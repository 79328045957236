import TApiConnector from '@global/t-api';

class _Reports {
    getReportsList(params) {
        return TApiConnector
            .request('my-tasks.index', params)
            .then(response => response.data);
    }

    setReportsViewed(data) {
        return TApiConnector
            .request('my-tasks.set.viewed', null, null, null, data)
            .then(response => response.data);
    }
}

const Reports = new _Reports();
export default Reports;

import Vue from 'vue';
import Vuex from 'vuex';

import breadcrumbs from '@admin/store/modules/breadcrumbs';
import assignments from '@admin/store/modules/assignments';
import activities from '@admin/store/modules/activities';
import users from '@admin/store/modules/users';
import programs from '@admin/store/modules/programs';
import structure from '@admin/store/modules/structure';
import reports from '@admin/store/modules/reports';
import personalAccount from '@admin/store/modules/personalAccount';
import schoolRegistration from '@admin/store/modules/schoolRegistration';
import common from '@admin/store/modules/common';

Vue.use(Vuex);

export default {
    breadcrumbs,
    assignments,
    activities,
    users,
    programs,
    structure,
    reports,
    personalAccount,
    schoolRegistration,
    common
};

import TApiConnector from '@global/t-api';

class _Programs {
    getProgramsList(params) {
        return TApiConnector
            .request('program.index', params)
            .then(res => res.data);
    }

    getProgram(params) {
        return TApiConnector
            .request('program.show', params)
            .then(res => res.data);
    }

    getProgramUsersList(params) {
        return TApiConnector
            .request('program.assignments.list', params)
            .then(res => res.data);
    }

    createProgram(data) {
        return TApiConnector
            .request('program.store', null, null, null, data)
            .then(res => res.data);
    }

    updateProgram({ params, data }) {
        return TApiConnector
            .request('program.update', params, null, null, data)
            .then(res => res.data);
    }

    deleteProgram(params) {
        return TApiConnector
            .request('program.destroy', params)
            .then(res => res.data);
    }

    toggleProgramActivity(data) {
        return TApiConnector
            .request('program.status.toggle', null, null, null, data)
            .then(res => res.data);
    }

    getProgramStylesList() {
        return TApiConnector
            .request('program.style.list')
            .then(response => response.data);
    }

    uploadProgramStyle(data, config) {
        return TApiConnector
            .request('program.style.upload', null, null, config, data)
            .then(response => response.data);
    }

    deleteProgramStyle(data) {
        return TApiConnector
            .request('program.style.destroy', null, null, null, data)
            .then(res => res.data);
    }

    downloadUsersResults(params) {
        return TApiConnector
            .request('program.progress.download', params)
            .then(response => response.data);
    }
}

const Programs = new _Programs();
export default Programs;

<template>
    <v-list
        :class="{
            'menu_lg': $vuetify.breakpoint.lgAndUp,
            'menu_md': $vuetify.breakpoint.mdOnly,
            'menu_sm': $vuetify.breakpoint.smOnly,
            'menu_xs': $vuetify.breakpoint.xsOnly
        }"
        class="menu"
    >
        <!-- ----------------- 1 УРОВЕНЬ ВЛОЖЕННОСТИ ----------------- -->
        <template v-for="first in menuFormatted">

            <!-- Элементы 1 уровня без подменю -->
            <v-list-item
                v-if="!first.submenu && !first.hide"
                :key="first.name"
                :to="first.path"
                class='menu__item notranslate'
                :class="{[`menu__item_${first.alias}`]: true}"
                :data-qa="`menu-item__${first.alias}`"
                @click="$emit('routeChange')"
            >
                <v-list-item-action>
                    <v-icon class="notranslate">{{ first.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ first.name }}</v-list-item-title>
            </v-list-item>

            <!-- Элементы 1 уровня с подменю -->
            <v-list-group
                v-else-if="!first.hide"
                :key="first.name"
                no-action
                :prepend-icon="first.icon"
                class="menu__item notranslate"
                :class="{[`menu__item_${first.alias}`]: true}"
                :data-qa="`menu-item__${first.alias}`"
                :value="first.active"
            >
                <template v-slot:activator>
                    <v-list-item-title>{{ first.name }}</v-list-item-title>
                </template>

                <!-- ----------------- 2 УРОВЕНЬ ВЛОЖЕННОСТИ ----------------- -->
                <template v-for="second in first.submenu">

                    <!-- Элементы 2 уровня без подменю -->
                    <v-list-item
                        v-if="!second.submenu && !second.hide && !second.action"
                        :key="second.name"
                        :to="second.path"
                        class="menu__item notranslate"
                        :class="{[`menu__item_${second.alias}`]: true}"
                        :data-qa="`menu-item__${second.alias}`"
                        @click="$emit('routeChange')"
                    >
                        <v-list-item-action>
                            <v-icon class="notranslate">{{ second.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ second.name }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        v-if="!second.submenu && !second.hide && second.action"
                        :key="second.name"
                        exact
                        class="menu__item notranslate"
                        :class="{[`menu__item_${second.alias}`]: true}"
                        :data-qa="`menu-item__${second.alias}`"
                        @click="handleItemClick(second)"
                    >
                        <v-list-item-action>
                            <v-icon class="notranslate">{{ second.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ second.name }}</v-list-item-title>
                    </v-list-item>

                </template>

            </v-list-group>

        </template>
        <slot />
    </v-list>
</template>

<script>
export default {
    name: 'SidebarMenu',
    props: {
        menu: {
            type: Array
        }
    },
    data() {
        return {
            drawer: true,
            miniVariant: true
        };
    },
    computed: {
        currentRoute() {
            return this.$route.name;
        },
        menuFormatted() {
            if (!this.menu) return [];
            return this.menu.map(item => {
                const formattedItem = { ...item };
                if (formattedItem.submenu) {
                    formattedItem.active = formattedItem.submenu.some(
                        item => item.routeName === this.currentRoute
                    );
                }
                return formattedItem;
            });
        }
    },
    methods: {
        handleItemClick(item) {
            item.action(item);
            this.$emit('routeChange');
        }
    }
};
</script>

<style lang="scss" scoped>
.menu {
    padding: 0 !important;

    &__item {
        &--courses-list {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    &_lg {
        padding-left: 0;
    }

    &_md {
        padding-left: 24px;
    }

    &_sm {
        padding-left: 16px;
    }

    &_xs {
        padding: 0;

        ::v-deep .v-list-item {
            padding: 0;
            height: 60px;

            &--link {
                padding: 0 30px;
            }
        }

        ::v-deep .v-list__group__header {
            padding: 0 30px;
        }

        ::v-deep .v-list__group__header .v-list__group__header__prepend-icon {
            padding: 0;
        }

        ::v-deep .v-list__group__items--no-action .v-list-item {
            padding-left: 50px;
        }
    }
}

::v-deep .v-list__group {
    &:before,
    &:after {
        background: transparent !important;
    }
}

::v-deep .v-list-item__title {
    font-size: 16px;
    font-weight: 700;
    color: $primary-gray;
    white-space: normal;
}

::v-deep .v-list__group__header .v-list__group__header__prepend-icon {
    min-width: 40px;
    padding: 0 8px;
}

::v-deep .v-list__group__items--no-action .v-list-item {
    padding-left: 22px;
}
</style>

import schoolRegistration from '@admin/api/schoolRegistration';

export const state = {
    schoolById: {
        school_id: [1]
    }
};

export const getters = {
    schoolById: state => state.schoolById
};

export const mutations = {
    SET_SCHOOL: (state, school) => {
        state.schoolById = school;
    }
};

export const actions = {
    async getSchoolDetails({ commit }, params) {
        try {
            const school = await schoolRegistration.getSchool(params);
            commit('SET_SCHOOL', school.data);
            return school.data;
        } catch (e) {
            console.error(e);
        }
    },
    updateSchool({ commit }, { params, data }) {
        return schoolRegistration.updateSchool(params, data);
    },
    createSchool({ commit }, data) {
        return schoolRegistration.createSchool(data);
    },
    deleteSchool({ commit }, params) {
        return schoolRegistration.deleteSchool(params);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

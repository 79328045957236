import TApiConnector from '@global/t-api';

class _Assignments {
    getAssignmentsList(params) {
        return TApiConnector
            .request('assignments-pack.index', params)
            .then(res => res.data);
    }

    getAssignmentUsersList(params) {
        return TApiConnector
            .request('assignments-pack.assignments', params)
            .then(res => res.data);
    }

    getAssignment(params) {
        return TApiConnector
            .request('assignments-pack.show', params)
            .then(res => res.data);
    }

    createAssignment(data) {
        return TApiConnector
            .request('assignments-pack.store', null, null, null, data)
            .then(res => res.data);
    }

    updateAssignment({ params, data }) {
        return TApiConnector
            .request('assignments-pack.update', params, null, null, data)
            .then(res => res.data);
    }

    deleteAssignment(params) {
        return TApiConnector
            .request('assignments-pack.destroy', params)
            .then(res => res.data);
    }

    downloadUsersResults(params) {
        return TApiConnector
            .request('assignments-pack.user-results.download', params)
            .then(response => response.data);
    }
}
const Assignments = new _Assignments();
export default Assignments;

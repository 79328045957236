<template>
    <div class="file-upload">
        <span
            v-if="!loading && !uploadedFiles.length && uploadTitle"
            class="grey--text"
        >
            {{ uploadTitle }}
        </span>

        <kmFile
            v-model="files"
            v-if="!loading && !uploadedFiles.length"
            :class="[
                'mt-2',
                kmFileClasses
            ]"
            :disabled="disabled"
            :dropzone="dropzone"
            :accept="accept"
            :subtitle="kmFileSubtitle"
            @change="onFileChange($event)"
        />

        <div
            v-if="loading && !uploadedFiles.length"
            class="grey--text mt-3"
        >
            <span>
                Идет загрузка: {{ downloadProgress }}%
            </span>

            <v-progress-linear
                :value="downloadProgress"
                class="mt-4"
                color="primary"
                buffer-value="0"
                stream
            />
        </div>

        <Preview
            v-model="uploadedFiles"
            v-if="uploadedFiles.length"
            :disabled="disabled"
            :type="previewType"
            @change="onPreviewChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'FileUpload',
    model: {
        prop: 'files',
        event: 'file-changed'
    },
    props: {
        uploadTitle: String,
        uploadedFiles: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dropzone: {
            type: Boolean,
            default: true
        },
        kmFileClasses: String,
        accept: String,
        downloadProgress: Number,
        previewType: String
    },
    data() {
        return {
            files: []
        };
    },
    computed: {
        kmFileSubtitle() {
            return this.$vuetify.breakpoint.mdAndUp
                ? 'или перетащите файл в эту область'
                : '';
        }
    },
    methods: {
        onFileChange(e) {
            this.$emit('file-changed', e);
        },
        onPreviewChange(e) {
            this.files = e;
            this.$emit('preview-changed', e);
        }
    }
};
</script>

<style lang="scss" scoped>

</style>

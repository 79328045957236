import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@client/store/modules/auth';
import assignment from '@client/store/modules/assignment';
import ws from '@client/store/modules/ws';
import clientBreadcrumbs from '@client/store/modules/clientBreadcrumbs';

Vue.use(Vuex);

export default {
    assignment,
    auth,
    ws,
    clientBreadcrumbs
};

<template>
    <v-snackbar
        v-model="snackbar"
        v-if="currentSnackbarStatus"
        :timeout="timeout"
        light
        right
    >
        <v-card :class="msgLevel">
            <v-card-title class="px-4 pb-3 pt-2">
                <div class="flex-grow-1">
                    <div class="body-1">
                        <h4>{{ title }}</h4>

                        <v-btn
                            class="my-0 ml-auto mr-n2"
                            icon
                            @click="snackbar = false"
                        >
                            <v-icon>close</v-icon>
                        </v-btn>
                    </div>

                    <div class="body-1--multiline subtitle-2 my-2">
                        {{ text }}
                    </div>
                </div>
            </v-card-title>
        </v-card>
    </v-snackbar>
</template>

<script>
export default {
    name: 'Snackbar',
    computed: {
        ...mapGetters({
            currentSnackbar: 'snackbar/currentSnackbar',
            currentSnackbarStatus: 'snackbar/currentSnackbarStatus'
        }),
        snackbar: {
            set(data) {
                this.closeSnackbar();
            },
            get() {
                return this.currentSnackbarStatus;
            }
        },
        title() {
            if (this.currentSnackbar) {
                return this.currentSnackbar.title;
            }

            return 'Титул';
        },
        text() {
            if (this.currentSnackbar) {
                return this.currentSnackbar.text;
            }

            return 'Текст';
        },
        timeout() {
            if (this.currentSnackbar) {
                return this.currentSnackbar.timeout;
            }

            return 5000;
        },
        msgLevel() {
            if (this.currentSnackbar) {
                return `v-snack-${this.currentSnackbar.level}`;
            }
            return '';
        }
    },
    methods: {
        ...mapActions({
            closeSnackbar: 'snackbar/closeSnackbar'
        })
    }
};
</script>

<style lang="scss" scoped>
.body-1 {
    display: flex;
    align-items: flex-start;

    h4 {
        align-self: center;
        line-height: 1.4;
        word-break: break-word;
    }
}

::v-deep {
    .v-application.theme--light .v-snack & {
        .v-snack__wrapper {
            max-width: 100vw;
            min-width: 300px;
        }

        .v-snack__action {
            display: none;
        }

        .v-card {
            width: 100%;
        }
    }
}
</style>

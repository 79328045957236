import TApiConnector from '@global/t-api';

class _Activities {
    getActivitiesList(params) {
        return TApiConnector
            .request('content.index', params)
            .then(response => response.data);
    }

    getAllActivitiesList(params) {
        return TApiConnector
            .request('content.list.all', params)
            .then(response => response.data);
    }

    getActivity(params) {
        return TApiConnector
            .request('content.show', params)
            .then(response => response.data);
    }

    getActivityUsersList(params) {
        return TApiConnector
            .request('content-assignments.list', params)
            .then(res => res.data);
    }

    createActivity(data) {
        return TApiConnector
            .request('content.store', null, null, null, data)
            .then(response => response.data);
    }

    updateActivity(params, data) {
        return TApiConnector
            .request('content.update', params, null, null, data)
            .then(response => response.data);
    }

    deleteActivity(params) {
        return TApiConnector
            .request('content.destroy', params)
            .then(response => response.data);
    }

    uploadActivityFile(data, config) {
        return TApiConnector
            .request('files.upload', null, null, config, data)
            .then(response => response.data);
    }

    downloadUsersResults(params) {
        return TApiConnector
            .request('content.progress.download', params)
            .then(response => response.data);
    }

    uploadActivityFileCover(data, config) {
        return TApiConnector
            .request('files.upload.cover', null, null, config, data)
            .then(response => response.data);
    }
}

const Activities = new _Activities();
export default Activities;

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" id="svgicon_burger_a"/></defs><use _fill="#D8D8D8" xlink:href="#svgicon_burger_a" fill-rule="evenodd"/>'
  }
})

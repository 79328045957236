<template>
    <div class="file">
        <div>
            <label v-if="!dropzone" :class="{'file__label-text': !disabled}">
                <div class="v-btn v-btn--flat theme--light text-btn">
                    <i class="material-icons">{{ icon }}</i>
                    <span class="file__title">{{ title }}</span>
                    <input
                        v-if="!disabled"
                        ref="file"
                        :multiple="multiple"
                        :accept="accept"
                        type="file"
                        class="file__input"
                        @change="onFileChange"
                    >
                </div>
            </label>
            <div
                id="dropzone"
                v-if="dropzone"
                ref="dropzone"
                class="dropzone"
                :class="{disabled}"
                @dragover="dragoverFile"
                @dragleave="dragleaveFile"
                @drop="dropFile"
            >
                <label class="file__label my-7">
                    <div class="v-btn v-btn--depressed theme--light px-4 file__btn">
                        <i class="material-icons file__ico">attachment</i>
                        <span class="file__title">{{ title }}</span>
                        <input
                            v-if="!disabled"
                            ref="file"
                            type="file"
                            :accept="accept"
                            class="file__input"
                            :multiple="multiple"
                            @change="onFileChange"
                        >

                    </div>
                    <span class="pl-4"> {{ subtitle }}</span>
                </label>
            </div>
            <span v-if="errorMsg.length" class="red--text"> {{ errorMsg }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'kmFile',
    model: {
        prop: 'files',
        event: 'change'
    },
    props: {
        file: {},
        title: {
            type: String,
            default: 'Выберите файл'
        },
        subtitle: {
            type: String,
            default: 'или перетащите картинку в эту область'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: '*'
        },
        dropzone: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'add'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            files: [],
            model: {},
            errorMsg: '',
            isCorrectFormat: true
        };
    },
    computed: {
        isFiles() {
            return !!this.files.length;
        }
    },
    watch: {
        files(val, oldVal) {
            if (oldVal && !val) {
                this.clearFile();
            }
        }
    },
    methods: {
        dropFile(e) {
            e.preventDefault();
            this.isCorrectFormat = false;
            this.errorMsg = '';
            this.$refs.dropzone.className = 'dropzone';
            this.files = e.dataTransfer.files;
            if (!this.disabled) {
                if (this.accept !== '*') {
                    const formats = this.accept.split(',');
                    formats.forEach(format => {
                        const regexp = new RegExp(`\\.*\\${format}$`);
                        this.files.forEach(file => {
                            if (file.name.match(regexp) !== null) this.isCorrectFormat = true;
                        });
                    });
                }
            } else {
                this.isCorrectFormat = true;
            }
            if (this.isCorrectFormat) {
                if (!this.multiple && this.files.length > 1) {
                    this.files = [];
                    this.errorMsg = 'Загрузить можно только один файл';
                } else {
                    this.$emit('change', this.files);
                }
            } else {
                this.files = [];
                this.errorMsg = 'Неверный формат файла';
            }
        },
        dragoverFile(e) {
            e.preventDefault();
            if (!this.disabled) this.$refs.dropzone.className = 'dropzone dragover';
        },
        dragleaveFile(e) {
            e.preventDefault();
            if (!this.disabled) this.$refs.dropzone.className = 'dropzone';
        },
        onFileChange(e) {
            this.$emit('change', e.target.files);
        },
        clearFile() {
            this.$refs.file.value = '';
            this.files = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.dropzone {
    border: 2px dotted #ccc;
    color: #ccc;
    border-radius: 5px;
}

.disabled {
    background-color: rgb(207, 204, 204);
    color: #fff
}

.block-size{
    overflow: hidden;
    &__content {
        white-space: nowrap;
    }
}

.dropzone.dragover {
    border-color: rgb(160, 160, 160);
    color: rgb(160, 160, 160);
}

.file{
    &__label {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__label-text {
        cursor: pointer;

        .text-btn:hover {
            color: #45ace1 !important;
        }
    }

    &__ico{
        transform: rotate(140deg);
        will-change: transform;
    }

    &__format {
        background-color: #000;
        color: #ffffff;
        padding: 3px 5px;
        border-radius: 5px;

    }

    &__btn {
        cursor: pointer;
        margin: 0 !important;
    }

    &__input{
        outline:0;
        opacity:0;
        pointer-events:none;
        user-select:none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
    }
}
</style>

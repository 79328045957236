/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo_palms': {
    viewBox: '0 0 904.2 700',
    data: `
    <defs>
        <style>
            .a { fill:#6220b3; }
            .b { fill:#7fdd36; }
        </style>
    </defs>
    <title>Palms Learning</title>
    <path class="a" d="M91.92915,527.76528c-.05041-8.46915-.1264-16.58473-.14245-24.70047-.09581-48.43211-.12957-96.8644-.35515-145.29586-.01822-3.90968,1.97054-3.86855,4.64071-3.87191q31.459-.03924,62.91788-.129c31.11692-.10246,62.23375-.27336,93.35073-.31108,4.10168-.005,8.20412.60264,12.29661.92579,2.2636,32.70837-25.1687,83.69541-86.00525,86.48442-1.30568,32.13658-14.61044,57.79042-42.6959,74.80753A81.01721,81.01721,0,0,1,91.92915,527.76528Z"/>
    <path class="a" d="M552.78113,439.611c-.09778-29.02458-.19395-57.56795-.292-86.66745,58.00921-.23031,115.49743-.45859,173.79-.69.05127,29.17329.10157,57.83215.15282,87.03281C668.50268,439.39467,611.13619,439.50191,552.78113,439.611Z"/>
    <path class="b" d="M93.48707.26618c8.97552-.81873,17.10243.87223,25.02855,3.28039a87.19876,87.19876,0,0,1,61.95,83.54037q.204,40.47023.38835,80.94053c.00673,1.638-.2411,3.27714-.431,5.68237-28.54223.05777-56.92558.1152-86.21848.1745C93.96531,116.004,93.7271,58.35521,93.48707.26618Z"/>
    <path class="a" d="M455.18744,345.73743c-.03229-11.40652-.10025-22.35747-.08623-33.30832.02575-20.12973-.99074-40.33387.37805-60.37375,2.95282-43.232,36.12755-76.04391,79.33641-79.96081,2.096-.19,4.22353-.03374,6.73569-.03966.08016,57.95679.15967,115.45578.24029,173.73166C512.8825,345.77014,484.22454,345.75391,455.18744,345.73743Z"/>
    <path class="b" d="M274.311.01129c.165,57.702.32909,115.1063.49535,173.26718-5.1727.08764-10.07447.21826-14.97675.24561-22.317.12435-44.63446.19062-66.95114.35618-3.39974.02519-4.91062-.7386-4.89421-4.76255.11072-27.15.13385-54.3046-.25258-81.45147C187.0557,40.17482,227.02437-.78025,274.311.01129Z"/>
    <path class="a" d="M271.489,346.262c1.30667-32.56282,14.63375-58.01821,42.22412-74.27438,31.3661-18.48094,63.51663-17.567,93.79212,2.76109,24.64831,16.5498,36.78561,40.69684,37.84323,71.14173C387.09008,346.01492,329.57672,346.13784,271.489,346.262Z"/>
    <path class="a" d="M552.23452,258.7444c23.07059.35681,42.63285,7.81955,59.06813,23.09091,16.40824,15.24622,25.31412,34.35419,28.14209,56.6107,1.42291-12.25571,4.61151-23.8307,10.60156-34.43779,14.84421-26.28581,37.141-41.66833,67.29051-45.2783,1.64623-.19708,3.32747-.08147,4.98634-.19753,2.73763-.1916,3.83615.9232,3.8297,3.76761-.06074,26.65192-.02133,53.30406-.01938,79.95613,0,.65933-.09763,1.31867-.23927,3.11243-57.68722.06266-115.31548.12523-173.34173.18824C552.44622,316.54178,552.34211,288.11928,552.23452,258.7444Z"/>
    <path class="a" d="M264.91622,346.3414c-57.7359.09609-115.2172.19179-173.48208.28876.84157-28.4838,11.53385-51.51713,33.58305-68.306,28.87847-21.98879,60.44-24.66713,92.98592-8.854C246.59818,283.36381,265.78044,315.15932,264.91622,346.3414Z"/>
    <path class="b" d="M86.63328,259.46453a76.09058,76.09058,0,0,1-28.46969-4.5844C22.85184,242.53908-.311,208.50711.2024,171.00167c.3579-26.14487-.1103-52.301-.2021-78.45214-.00515-1.466.05706-2.93216.09426-4.69965,9.5893-.49713,18.51786,1.29714,27.20433,4.21577C63.158,104.11418,86.55126,136.42313,86.9111,174.73929c.25182,26.81448.06,53.63311.04671,80.44989C86.95716,256.48321,86.76652,257.77712,86.63328,259.46453Z"/>
    <path class="a" d="M359.07033,353.26067c28.85256-.033,57.11216-.06533,86.06238-.09845.09611,6.33521.23083,12.41791.27293,18.50119.14734,21.295.24017,42.5903.41705,63.885.02389,2.87812-.398,4.66031-4.0195,4.64008-26.12157-.14578-52.24414-.13172-78.36639-.138-2.713-.00055-4.76739-.10068-4.75965-3.88029.0518-25.28883-.09879-50.578-.1403-75.86711C358.53337,358.1839,358.849,356.06417,359.07033,353.26067Z"/>
    <path class="a" d="M455.18618,353.19719c17.247-.06274,33.69088-.08419,50.13414-.19619q15.96942-.10876,31.93672-.45958c3.13827-.07121,4.97564.52653,4.95523,4.29051-.14144,26.11779-.1316,52.23644-.19036,78.35476-.003,1.29985-.19239,2.5993-.32509,4.27995-8.58307.09127-16.85047.22375-25.11828.25824-19.1294.07957-38.26023.009-57.38724.25444-3.57667.046-4.24581-1.44569-4.227-4.3917q.176-27.44526.25869-54.89117C455.25155,371.73559,455.20158,362.77447,455.18618,353.19719Z"/>
    <path class="a" d="M736.064,437.83633c-.0174-28.53207-.03486-57.08588-.05264-86.21572,29.03757-.125,57.44944-.24741,86.77211-.3737.13442,20.99268-6.571,39.21146-19.51164,55.24607C785.2059,428.87821,756.98517,438.93647,736.064,437.83633Z"/>
    <path class="a" d="M736.60676,344.83126c-6.17192-40.75253,45.2575-92.3413,85.8093-85.78928.13771,8.198.36633,16.47766.39787,24.758.07306,19.13421-.0235,38.26945.13854,57.40266.02747,3.24453-.81026,4.39343-4.1646,4.37249-25.12192-.157-50.245-.14324-75.36744-.22893C741.13221,345.33843,738.84509,345.008,736.60676,344.83126Z"/>
    <path class="b" d="M367.50989,87.06315c.04827,28.71494.096,57.12331.14478,86.1316L281.78,173.445C278.68387,128.444,320.24807,85.609,367.50989,87.06315Z"/>
    <path class="a" d="M867.01153,176.85605a40.366,40.366,0,0,1,36.98383,43.8798c-2.00774,22.67085-21.98113,39.63071-44.18491,37.51851-22.84343-2.17306-39.16186-22.57785-36.98152-46.24207C824.73461,191.32957,845.26642,174.99237,867.01153,176.85605Z"/>
    <path class="a" d="M308.69458,439.67227c-22.65839-1.72831-38.84608-20.53108-37.14688-43.14761a40.69847,40.69847,0,0,1,43.3039-37.35572c22.21283,1.64212,39.21045,21.22986,37.4367,43.14135A40.32227,40.32227,0,0,1,308.69458,439.67227Z"/>
    <path d="M225.86508,214.90652c.98462-2.27226,2.05572-4.51158,2.93728-6.82314,2.411-6.32193,5.14644-6.80782,9.84357-1.67268a3.32729,3.32729,0,0,0,.93365-.20889c4.51461-2.49016,9.32467-4.72987,13.94226-.96486,4.5769,3.73184,3.58134,8.90077,2.02239,13.85131-1.9475,6.18441-3.664,12.46193-6.04565,18.47537-.61885,1.56264-3.34287,2.29152-5.1,3.40336-1.1663-1.92737-3.5705-4.08468-3.26336-5.73836.86959-4.68192,2.89433-9.143,4.376-13.71966.99793-3.08241,1.8464-6.21321,2.86764-9.6821-8.26264,1.35038-15.83913,9.09829-17.79,17.51543-.52542,2.26707-.9137,4.56793-1.30538,6.86343-.41127,2.41063-1.089,5.34591-4.063,4.21067-1.9601-.74819-4.53447-3.68342-4.38679-5.43275.41374-4.90057,2.05793-9.70409,3.29989-14.52445.49574-1.92416,1.20966-3.79207,1.82424-5.6856Z"/>
    <path d="M309.24965,216.47841c-1.59656-2.15613-2.86891-3.87441-4.14123-5.59268,1.8305-1.01492,3.72755-1.92934,5.46717-3.08151,1.08685-.71981,2.4737-1.64412,2.78915-2.745.86167-3.00758.88894-6.27512,1.9109-9.20827.64706-1.85708,2.48339-3.2998,3.78631-4.92837,1.44186,1.73757,3.89706,3.36854,4.09369,5.23713.31374,2.98116-.87474,6.1204-1.57533,10.01046,3.29259-.64744,6.10681-1.1752,8.90926-1.75934,2.58526-.53887,5.81654-1.78138,6.7498,1.74265.99618,3.76168-2.50712,4.46069-5.22621,5.24328-2.5506.73419-5.1344,1.35227-7.69826,2.04112-5.18452,1.3931-9.45905,10.28163-6.79089,14.87575.55751.96,3.87075,1.53332,4.82353.82272,2.87621-2.14524,5.11513-5.122,7.81021-7.54342,1.20226-1.08019,2.90115-1.60765,4.37464-2.386.24185,1.74025,1.29956,4.00142.58024,5.128-2.69924,4.22755-5.44072,8.72022-9.19516,11.89805-3.60025,3.04729-8.52177,2.40877-12.71331.03343-3.9593-2.24369-4.88588-6.10008-4.683-10.30339C308.68093,222.65355,309.02267,219.353,309.24965,216.47841Z"/>
    <path d="M116.4919,234.5696c3.33492-13.734,6.40551-26.30538,9.42525-38.88892.502-2.092.53854-4.32356,1.24864-6.32827.49271-1.391,1.7-3.44052,2.71614-3.51812a5.7362,5.7362,0,0,1,4.466,2.13823,8.18665,8.18665,0,0,1,1.09946,5.54626q-4.40193,23.202-9.30044,46.30732a6.41019,6.41019,0,0,1-3.47917,4.02415c-.81649.30931-3.02-1.58383-3.78993-2.942A36.107,36.107,0,0,1,116.4919,234.5696Z"/>
    <path d="M225.95777,214.7736c-2.262,2.77741-4.37673,3.921-7.95215,1.29928-1.30523-.95709-4.98532-.03321-6.73344,1.21805-5.97062,4.2737-6.27169,11.30572-7.61894,17.75417a6.075,6.075,0,0,0-.06817.99326c-.1135,2.82738.22938,6.08264-4.02491,5.96741-2.68113-.07257-5.24822-4.39872-4.63123-7.70691,1.1254-6.03436,2.41062-12.03877,3.58074-18.06506a57.18019,57.18019,0,0,1,.96291-5.87961,48.89239,48.89239,0,0,1,2.59066-5.81066c1.88027,1.05518,3.76053,2.11039,6.10729,3.42737,9.55548-5.27593,14.31064-3.61222,17.67658,6.95425C225.86508,214.90652,225.95777,214.7736,225.95777,214.7736Z"/>
    <path d="M340.28863,216.98569c4.68786-10.12555,8.97844-19.77011,13.72168-29.18667.63132-1.25339,3.55956-1.34983,5.42407-1.982.16181,1.67758.86253,3.52426.39777,5.00524-2.97279,9.47313-6.02026,18.93479-9.53877,28.21225-.53891,1.421-3.69471,2.64318-5.53914,2.514C343.38105,221.45238,342.17117,219.02475,340.28863,216.98569Z"/>
    <path d="M287.89,234.51278c2.171-8.33664,4.106-16.74876,6.72648-24.94163.48248-1.50845,3.808-3.07089,5.70457-2.93838,2.85969.19978,2.97177,2.7368,2.29168,5.38521-2.10216,8.18647-3.94841,16.43833-5.93991,24.65368-.586,2.41715-.97331,5.21826-4.68231,4.134C288.84834,239.88718,287.15357,238.00523,287.89,234.51278Z"/>
    <path d="M346.61933,226.61578c.73042,1.22389,2.7631,3.20728,3.04091,5.4118.47772,3.79073-2.98025,6.32988-7.27077,6.06454-3.21787-.199-5.40556-1.50285-5.10318-5.187C337.57381,229.40179,341.24308,226.31581,346.61933,226.61578Z"/>
    <path d="M301.6577,202.34569c-1.97144-3.14979-4.35227-5.45776-3.88711-6.52323.95583-2.18949,3.4349-3.714,5.287-5.51219,1.47719,1.65378,4.38265,3.60632,4.11316,4.89477C306.71049,197.40561,304.25928,199.18979,301.6577,202.34569Z"/>
    <path d="M190.34665,206.26407c-5.44224-.76535-11.15186-1.75778-16.169,2.34979-6.68992,5.477-11.23639,12.10371-12.08492,19.06621-3.51427,1.2104-6.169,1.788-8.49411,2.99889-4.25042,2.21362-8.313,4.75591-13.20395,2.4055q-.21951-.7976-.43891-1.59516c1.419-.4647,2.84869-.89947,4.2551-1.39969,4.0455-1.439,8.32661-2.45179,12.06013-4.47265,4.79229-2.59384,6.13794-7.23443,4.372-11.91641-1.70724-4.52636-5.5158-6.85878-10.69422-6.25714-9.48531,1.10194-13.572,9.12571-18.35773,15.58839-1.71344,2.31371-.568,7.27841.24219,10.81078,1.4864,6.48105,5.01641,9.01176,11.75019,9.39805,7.78075.44636,14.18165-2.50554,19.32371-8.29159,5.76729,7.70731,5.76729,7.70731,16.6825,2.85191a12.0147,12.0147,0,0,0,2.13864,2.5722c1.24795.86765,2.92149,2.10662,4.09146,1.82957,1.1948-.28293,2.56982-2.07448,2.885-3.43316,1.9514-8.4118,3.67877-16.87723,5.36674-25.34755C194.72863,210.12421,193.6831,206.73329,190.34665,206.26407Zm-37.03477,8.85876c4.76225,1.26973-9.2212,4.04556-9.2212,4.04556S148.54952,213.85309,153.31188,215.12283Zm17.76154,16.961c-.00736-8.081,5.21215-14.65883,13.48682-17.29479C182.597,222.70011,177.70005,227.6704,171.07342,232.08381Z"/>`
  }
})

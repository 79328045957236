<template>
    <v-app>
        <router-view />
        <Snackbar />
    </v-app>
</template>

<script>
import debounce from 'lodash/debounce';
import Snackbar from '@components/main/Snackbar';

export default {
    name: 'App',
    components: {
        Snackbar
    },
    beforeCreate() {
        if (!/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
            const style = document.createElement('style');
            style.innerHTML = `
                div::-webkit-scrollbar,
                body::-webkit-scrollbar {
                    background-color: #fff;
                    width: 16px
                }

                div::-webkit-scrollbar-track,
                body::-webkit-scrollbar-track {
                    background-color: #fff
                }

                div::-webkit-scrollbar-thumb,
                body::-webkit-scrollbar-thumb {
                    background-color: #babac0;
                    border-radius: 16px;
                    border: 4px solid #fff
                }

                div::-webkit-scrollbar-button,
                body::-webkit-scrollbar-button {
                    display: none
                }

                div,
                body {
                    scrollbar-color: #babac0;
                    scrollbar-width: thin;
                }
            `;
            document.head.appendChild(style);
        }
    },
    created() {
        window.addEventListener('offline', this.onAppOffline);
        window.addEventListener('online', this.onAppOnline);
    },
    mounted() {
        this.setScrollWidth();
        window.addEventListener('resize', debounce(this.onWindowResize, 500));
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        ...mapActions({
            addSnackbar: 'snackbar/addSnackbar',
            closeSnackbar: 'snackbar/closeSnackbar',
            setScrollbarWidth: 'globals/setScrollbarWidth'
        }),
        onAppOffline() {
            this.addSnackbar({
                type: 'custom',
                timeout: 0,
                title: 'Нет интернет-подключения',
                text: 'Последние действия были сохранены. Для продолжения работы проверьте соединение',
                level: 'error'
            });
        },
        onAppOnline() {
            this.closeSnackbar();
            this.addSnackbar({
                type: 'custom',
                timeout: 5000,
                title: 'Есть интернет-подключение',
                text: 'Соединение восстановлено',
                level: 'success'
            });
        },
        getScrollWidth() {
            const div = document.createElement('div');

            div.style.overflowY = 'scroll';
            div.style.position = 'absolute';
            div.style.width = '50px';
            div.style.height = '50px';
            div.style.visibility = 'hidden';

            document.body.appendChild(div);
            const scrollWidth = div.offsetWidth - div.clientWidth;
            document.body.removeChild(div);

            return scrollWidth;
        },
        setScrollWidth() {
            const scrollbarWidth = this.getScrollWidth();

            this.setScrollbarWidth(scrollbarWidth);

            document.documentElement.style.setProperty(
                '--scrollbar-width',
                `${scrollbarWidth}px`
            );
        },
        onWindowResize() {
            this.setScrollWidth();
        }
    }
};
</script>

<style lang="scss">
.el-date-editor, .el-time-panel, .el-picker-panel {
    font-family: 'Lato', sans-serif !important;
}
</style>

<template>
    <span
        class="shape trs"
        :style="style"
    />
</template>

<script>
export default {
    name: 'Shape',
    inheritAttrs: false,
    props: {
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        style() {
            let options = {};

            const { breakpoints, styles } = this.options;

            if (breakpoints && breakpoints.length) {
                breakpoints.forEach(breakpoint => {
                    if (this.$vuetify.breakpoint[breakpoint.name]) {
                        options = Object.assign({}, options, breakpoint);
                    }
                });
            }

            if (styles) {
                options = Object.assign({}, styles, options);
            }

            return options;
        }
    }
};
</script>

<style lang="scss" scoped>
.shape {
    position: absolute;
    z-index: 0;
}
</style>

<template>
    <div class="preloader">
        <template v-if="typeIsLeaf">
            <div class="leaf normal" />
            <div class="leaf reverse" />
            <div class="leaf normal" />
            <div class="leaf reverse" />
            <div class="leaf normal green-leaf" />
            <div class="leaf reverse" />
        </template>

        <template v-else-if="typeIsCircle">
            <v-progress-circular
                v-if="visible"
                :color="color"
                :indeterminate="animated"
                :size="size"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: 'Preloader',
    props: {
        type: {
            type: String,
            default: 'circle'
        },
        visible: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: 'primary'
        },
        animated: {
            type: Boolean,
            default: true
        },
        size: {
            type: Number,
            default: 50
        }
    },
    computed: {
        typeIsCircle() {
            return this.type === 'circle';
        },
        typeIsLeaf() {
            return this.type === 'leaf';
        }
    }
};
</script>

<style lang="scss" scoped>
.preloader {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

$size: 16px;
$violet: #8ed8fa;
$green: #ffbc59;

.leaf {
  height: $size * 2;
  width: $size;
  opacity: 0;
  background-color: $violet;
  margin-top: -$size * 0.2;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;

  &.green-leaf {
    background-color: $green;
  }

  &.normal {
    animation-name: leaf;
    margin-right: $size * 0.8;
    border-top-right-radius: $size;
    border-bottom-left-radius: $size;
  }

  &.reverse {
    animation-name: leaf-reverse;
    margin-left: $size * 0.8;
    border-top-left-radius: $size;
    border-bottom-right-radius: $size;
  }
}

@for $i from 1 through 6 {
  .leaf:nth-child(#{$i}) {
    animation-delay: #{$i * 0.25}s;
  }
}

@keyframes leaf {
  0% {
    opacity: 0;
    transform: translateX(-$size * 5);
  }
  30% {
    opacity: 1;
    transform: translateX(0);
  }
  60% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-$size * 10);
    opacity: 0;
  }
}

@keyframes leaf-reverse {
  0% {
    transform: translateX($size * 5);
  }
  30% {
    transform: translateX(0);
  }
  60% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-$size * 10);
    opacity: 0;
  }
}
</style>

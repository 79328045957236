import findValueDeep from 'deepdash-es/findValueDeep';
import Structure from '@admin/api/structure';

export const state = {
    programStructure: []
};

export const getters = {
    programStructure: state => state.programStructure
};

export const mutations = {
    SET_PROGRAM_STRUCTURE(state, structure) {
        state.programStructure = structure;
    },
    ADD_STRUCTURE_ELEMENT: (state, { newElement, parent }) => {
        if (!parent) {
            state.programStructure.push(newElement);
        } else {
            findValueDeep(
                state.programStructure,
                element => element.id === parent.id,
                { childrenPath: 'items' }
            ).items.push(newElement);
        }
    }
};

export const actions = {
    clearProgramStructure({ commit }) {
        commit('SET_PROGRAM_STRUCTURE', []);
    },
    async getProgramStructure({ commit }, programId) {
        if (!programId) return;

        const params = { program_id: programId };

        const { data } = await Structure.getProgramStructure(params);

        commit('SET_PROGRAM_STRUCTURE', data);
    },
    async saveProgramStructure({ getters }, programId) {
        if (!programId) return;

        const structure = getters.programStructure;
        const params = { program_id: programId };
        const payload = { data: { structure } };

        await Structure.saveProgramStructure(params, payload);
    },
    async addStructureElement({ commit, dispatch }, { newElement, parent }) {
        commit('ADD_STRUCTURE_ELEMENT', { newElement, parent });
        await dispatch('saveProgramStructure');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

import Assignments from '@admin/api/assignments';

export const state = {
    assignments: {
        data: [],
        meta: {}
    },
    assignmentById: {
        attributes: {
            meta: {
                criteria: {}
            }
        }
    },
    assignmentUsers: {
        data: [],
        meta: {}
    }
};

export const getters = {
    assignments: state => state.assignments,
    assignmentById: state => state.assignmentById,
    assignmentUsers: state => state.assignmentUsers
};

export const mutations = {
    SET_ASSIGNMENTS_LIST: (state, assignments) => {
        state.assignments = assignments;
    },
    SET_ASSIGNMENT_STATUS_UPDATE: (state, data) => {
        if (data.status === 'updated') {
            state.assignments.data = state.assignments.data.map(
                item => (item.id === data.payload.id ? data.payload : item)
            );
        }
        if (data.status === 'deleted') {
            state.assignments.data = state.assignments.data.filter(
                item => item.id !== data.payload.id
            );
        }
        if (data.status === 'created') {
            if (!state.assignments.data.includes(data.payload)) {
                state.assignments.data.push(data.payload);
            }
        }
    },
    SET_ASSIGNMENT_BY_ID: (state, assignment) => {
        state.assignmentById = assignment;
    },
    SET_ASSIGNMENT_USERS_LIST: (state, users) => {
        state.assignmentUsers = users;
    },
    SET_ASSIGNMENT_USERS_STATUS_UPDATE: (state, data) => {
        if (data.status === 'updated') {
            state.assignmentUsers.data = state.assignmentUsers.data.map(item => {
                if (item.id === data.payload.id) {
                    return Object.assign({}, data.payload);
                }
                return item;
            });
        }
        if (data.status === 'deleted') {
            state.assignmentUsers.data = state.assignmentUsers.data.filter(item => item.id !== data.payload.id);
        }
        if (data.status === 'created') {
            state.assignmentUsers.data.push(data.payload);
        }
    }
};

export const actions = {
    clearAssignment({ commit }) {
        commit('SET_ASSIGNMENT_BY_ID', { attributes: { meta: { criteria: {} } } });
    },
    clearAssignments({ commit }) {
        commit('SET_ASSIGNMENTS_LIST', {
            data: [],
            meta: {}
        });
    },
    getAssignments({ commit }, params) {
        return Assignments.getAssignmentsList(params).then(res => {
            commit('SET_ASSIGNMENTS_LIST', res);
        });
    },
    getAssignmentUsers({ commit }, params) {
        return Assignments.getAssignmentUsersList(params).then(res => {
            commit('SET_ASSIGNMENT_USERS_LIST', res);
        });
    },
    listenMessages({ commit, rootGetters }) {
        rootGetters['ws/server'].on('publish', res => {
            if (res.channel === 'admin') {
                if (res.data.payload.type === 'assignment_pack') {
                    commit('SET_ASSIGNMENT_STATUS_UPDATE', res.data);
                } else if (res.data.payload.type === 'assignment') {
                    commit('SET_ASSIGNMENT_USERS_STATUS_UPDATE', res.data);
                }
            }
        });
    },
    getAssignmentDetails({ commit }, params) {
        return Assignments.getAssignment(params).then(res => {
            commit('SET_ASSIGNMENT_BY_ID', res.data);
            return res.data;
        });
    },
    updateAssignment({ dispatch }, { params, data }) {
        return Assignments.updateAssignment({ params, data });
    },
    createAssignment({ commit }, data) {
        return Assignments.createAssignment(data);
    },
    deleteAssignment({ commit }, params) {
        return Assignments.deleteAssignment(params);
    },
    downloadUsersResults({ commit }, params) {
        return Assignments.downloadUsersResults(params);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

import router from '@router';

const prepareRoutes = (route, res, parent = null) => {
    const {
        redirect,
        name,
        path,
        children,
        meta: { breadcrumbs = {} } = {}
    } = route;

    if (redirect) return;

    const routeName = name || path.replace('/', '-');
    const pathChunks = path.split('/');
    let parentName = null;

    if (pathChunks.length > 1) {
        const [firstPathChunk] = pathChunks;
        const parentRoute = res.find(
            ({ path }) => path === firstPathChunk
        );

        parentName = parentRoute?.routeName;
    }

    if (name) {
        res.push({
            routeName,
            name: breadcrumbs.name || '{{name}}',
            parent: parent || parentName,
            disabled: !!breadcrumbs.disabled,
            path
        });
    }

    if (children) {
        children.forEach(
            child => prepareRoutes(child, res, name ? parent || routeName : parentName)
        );
    }
};

export const state = {
    breadcrumbs: {},
    currentBreadcrumbs: [],
    aliasses: [],
    isLoading: false,
    hasAliasPattern: false
};

export const getters = {
    breadcrumbs: state => state.breadcrumbs,
    currentBreadcrumbs: state => state.currentBreadcrumbs,
    aliasses: state => state.aliasses,
    isLoading: state => state.isLoading,
    hasAliasPattern: state => state.hasAliasPattern
};

export const mutations = {
    SET_BREADCRUMBS: (state, breadcrumbs) => {
        state.breadcrumbs = breadcrumbs;
    },
    SET_CURRENT_BREADCRUMBS: (state, breadcrumbs) => {
        state.currentBreadcrumbs = breadcrumbs;
    },
    SET_BREADCRUMB_NAME: (state, { index, value }) => {
        state.currentBreadcrumbs[index].text = value;
    },
    SET_ALIASSES: (state, aliasses) => {
        state.aliasses = aliasses;
    },
    SET_IS_LOADING: (state, loadingState) => {
        state.isLoading = loadingState;
    },
    SET_HAS_ALIAS_PATTERN: (state, patternState) => {
        state.hasAliasPattern = patternState;
    }
};

export const actions = {
    mapRoutes({ commit }, path) {
        const breadcrumbs = {};
        const preparedRoutes = [];
        const { children: adminRoutes } = router.options.routes.find(
            route => route.path === path
        );
        adminRoutes.forEach(route => prepareRoutes(route, preparedRoutes));

        if (preparedRoutes.length) {
            preparedRoutes.forEach(({ routeName, name, parent, disabled }) => {
                breadcrumbs[routeName] = {
                    routeName, name, parent, disabled
                };
            });

            commit('SET_BREADCRUMBS', breadcrumbs);
        }
    },
    getBreadcrumbPath({ getters, commit }, routeName) {
        const path = [];
        let route = routeName;

        commit('SET_HAS_ALIAS_PATTERN', false);

        while (true) {
            const currentRoute = getters.breadcrumbs[route];

            if (!currentRoute) {
                return path;
            }

            if (currentRoute.name.startsWith('{{')) {
                commit('SET_HAS_ALIAS_PATTERN', true);
            }

            route = currentRoute.parent;
            path.unshift(currentRoute);
        }
    },
    async getFormattedPath({ getters, commit, dispatch }, { routeName }) {
        commit('SET_IS_LOADING', true);

        const path = await dispatch('getBreadcrumbPath', routeName);
        let isAliasReplaced = false;

        const mappedPath = path.map((item, i) => {
            const resolvedPath = router.resolve({
                name: item.routeName
            });
            let { name } = item;

            if (getters.aliasses && getters.aliasses.length) {
                getters.aliasses.forEach(alias => {
                    const { pattern, value } = alias;

                    if (item.name === pattern) {
                        name = value;
                        isAliasReplaced = true;
                    }
                });
            }

            return {
                activeClass: item.disabled || i === path.length - 1
                    ? 'v-breadcrumbs__item--disabled'
                    : '',
                text: name,
                to: resolvedPath.href,
                disabled: item.disabled || i === path.length - 1
            };
        });

        commit('SET_CURRENT_BREADCRUMBS', mappedPath);

        if (!getters.hasAliasPattern || isAliasReplaced) {
            commit('SET_IS_LOADING', false);
        }
    },
    replaceAlias({ getters, commit }) {
        getters.aliasses.forEach(alias => {
            getters.currentBreadcrumbs.forEach((breadcrumb, index) => {
                const { routeName, pattern, value } = alias;

                if (breadcrumb.text === pattern) {
                    commit('SET_BREADCRUMB_NAME', { index, value, routeName });
                }
            });
        });
    },
    async setBreadcrumbName({ commit, dispatch }, { aliasses, routeName }) {
        commit('SET_ALIASSES', aliasses);
        await dispatch('getFormattedPath', { routeName });
    },
    removeAliasses({ commit }) {
        commit('SET_ALIASSES', []);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

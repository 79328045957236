import Auth from '@client/api/auth';
import TApiConnector from '@global/t-api';
import router from '@router';

export const state = {
    registrationEnabled: REGISTRATION_ENABLED,
    passwordRecoveryEnabled: PASSWORD_RECOVERY_ENABLED,
    isUserRequested: false,
    isUserAuth: false,
    targetPath: '/',
    error: '',
    user: {},
    roles: [],
    wsToken: '',
    formTypes: {
        LOGIN: 1,
        REGISTRATION: 2,
        RECOVERY: 3,
        MAIL_SENDED: 4
    },
    isTokenRequired: true
};

export const getters = {
    registrationEnabled: state => state.registrationEnabled,
    passwordRecoveryEnabled: state => state.passwordRecoveryEnabled,
    isUserRequested: state => state.isUserRequested,
    isUserAuth: state => state.isUserAuth,
    targetPath: state => state.targetPath,
    error: state => state.error,
    userId: state => state.user.user_id,
    userFullName: state => {
        const lastName = state.user.surname;

        if (!lastName) return '';

        const [firstName] = state.user.name;
        let formattedName = lastName;

        if (firstName) {
            formattedName = `${lastName} ${firstName}.`;
        }

        return formattedName;
    },
    userEmail: state => state.user.email,
    userRoles: state => state.roles,
    isUserAdmin: state => state.roles.includes('admin'),
    isUserListener: state => state.roles.includes('listener'),
    isUserDirector: state => state.roles.includes('director'),
    isUserChief: state => state.roles.includes('chief'),
    isUserCustomer: state => state.roles.includes('customer'),
    isUserExecutor: state => state.roles.includes('executor'),
    isUserTutor: state => state.roles.includes('tutor'),
    wsToken: state => state.wsToken,
    formTypes: state => state.formTypes,
    isHaveAccessByRoles: state => rolesToCheck => {
        const { roles = [] } = state;

        if (Array.isArray(rolesToCheck)) {
            return rolesToCheck.some(role => roles.includes(role));
        } else if (typeof rolesToCheck === 'string') {
            return roles.includes(rolesToCheck);
        }

        return false;
    },
    isTokenRequired: state => state.isTokenRequired
};

export const mutations = {
    SET_USER_IS_REQUESTED: state => {
        state.isUserRequested = true;
    },
    SET_USER_IS_NOT_REQUESTED: state => {
        state.isUserRequested = false;
    },
    SET_USER_IS_AUTH: state => {
        state.isUserAuth = true;
    },
    SET_USER_IS_NOT_AUTH: state => {
        state.isUserAuth = false;
    },
    SET_USER: (state, user) => {
        state.user = user;

        const { roles } = user;

        if (roles && roles.length) {
            state.roles = roles.map(role => role.name);
        }
    },
    SET_TARGET_PATH: (state, pathName) => {
        state.targetPath = (pathName && pathName !== '/auth') ? pathName : '/';
    },
    RESET_TARGET_PATH: state => {
        state.targetPath = '/';
    },
    SET_ERROR: (state, error) => {
        state.error = error;
    },
    RESET_ERROR: state => {
        state.error = '';
    },
    REMOVE_USER: state => {
        state.user = {};
    },
    SET_WS_TOKEN: (state, token) => {
        state.wsToken = token;
    },
    REMOVE_WS_TOKEN: state => {
        state.wsToken = '';
    },
    SET_TOKEN_REQUIRED: (state, data) => {
        state.isTokenRequired = data.auth_token_required;
    }
};

export const actions = {
    login: async ({ getters, commit, dispatch }, { email, password, login }) => {
        if (!email || !password) {
            const errorText = 'Вы не указали логин или пароль';
            commit('SET_ERROR', errorText);
            return;
        }

        try {
            const data = await Auth.login({ email, login, password });

            if (!data.status) {
                commit('SET_ERROR', 'Логин или пароль не совпадают');
                return;
                // commit('SET_ERROR', data.message); TODO message from backend
            }

            await dispatch('requestUser');

            await TApiConnector.requestRoutesWithPromise();

            commit('RESET_ERROR');
            commit('SET_USER_IS_AUTH');

            if (getters.isUserListener) {
                await router.push({ name: 'profile' });
            } else {
                await router.push(getters.targetPath);
            }

            commit('RESET_TARGET_PATH');
        } catch (e) {
            commit('SET_USER_IS_NOT_AUTH');
            commit('REMOVE_USER');
            dispatch('globals/setError', '500', { root: true });
        }
    },
    registration: async ({ commit, dispatch }, params) => {
        try {
            const { data } = await Auth.registration(params);

            if (!data.status) {
                commit('SET_ERROR', 'Ошибка при регистрации');
                return data;
            }

            commit('RESET_ERROR');
            return data;
        } catch (e) {
            dispatch('globals/setError', '500', { root: true });
        }
    },
    recover: async ({ commit, dispatch }, { email }) => {
        try {
            const { data } = await Auth.recover({ email });

            if (data && data.status === false) {
                commit('SET_ERROR', 'Данная электронная почта не существует в системе');
                return data;
            }

            commit('RESET_ERROR');
            return data;
        } catch (e) {
            dispatch('globals/setError', '500', { root: true });
        }
    },
    logout: async ({ dispatch }) => {
        try {
            const res = await Auth.logout();

            if (!res.status) {
                console.error('Error logout');
                return;
            }

            dispatch('removeUserSession');
        } catch (e) {
            dispatch('globals/setError', '500', { root: true });
        }
    },
    removeUserSession({ getters, commit, dispatch }) {
        if (!getters.isUserAuth) {
            return;
        }

        dispatch('ws/disconnect', null, { root: true });

        commit('SET_USER_IS_NOT_AUTH');
        commit('SET_USER_IS_NOT_REQUESTED');
        commit('REMOVE_USER');
        commit('REMOVE_WS_TOKEN');
    },
    requestUser: async ({ commit }) => {
        try {
            commit('SET_USER_IS_REQUESTED');

            const user = await Auth.getUser();

            commit('SET_USER', user);
            commit('SET_USER_IS_AUTH');

            return {
                statusCode: 200,
                user
            };
        } catch (e) {
            const { code } = e;

            commit('SET_USER_IS_NOT_AUTH');
            commit('REMOVE_USER');

            return {
                statusCode: code,
                user: null
            };
        }
    },
    checkTokenRequired: async ({ commit, dispatch }) => {
        try {
            const { data } = await Auth.isTokenRequired();
            commit('SET_TOKEN_REQUIRED', data);
        } catch (e) {
            console.error(e);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

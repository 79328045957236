<template>
    <v-dialog
        v-model="showConfirm"
        :width="width"
    >
        <v-card class="confirm">
            <v-card-title class="display-3 py-4 px-4">
                {{ title }}
                <slot name="title" />
            </v-card-title>

            <v-divider />

            <v-container grid-list-xl fluid>
                <v-layout>
                    <v-spacer />
                    <v-btn
                        depressed
                        class="confirm__btn-cancel"
                        @click="onCancel"
                    >
                        {{ cancelButton }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="error"
                        :loading="loading"
                        class="confirm__btn-ok"
                        @click="onConfirm"
                    >
                        {{ confrimButton }}
                    </v-btn>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Confirm',
    props: {
        title: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '500'
        },
        confrimButton: {
            type: String,
            default: 'Да'
        },
        cancelButton: {
            type: String,
            default: 'Отмена'
        }
    },
    computed: {
        showConfirm: {
            get() {
                return this.show;
            },
            set() {
                this.$emit('cancel');
            }
        }
    },
    watch: {
        show(value) {
            if (value) {
                document.addEventListener('keyup', this.keyupHandler);
            } else {
                document.removeEventListener('keyup', this.keyupHandler);
            }
        }
    },
    methods: {
        onCancel() {
            this.$emit('cancel');
        },
        onConfirm() {
            this.$emit('confirm');
        },
        keyupHandler(e) {
            if (e.key === 'Enter') {
                this.$emit('confirm');
            } else if (e.key === 'Escape') {
                this.$emit('cancel');
            }
        }
    }
};
</script>

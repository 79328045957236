/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'learning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" _fill="#45ACE1"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13 6h-2v8h2V6zm0 10a1 1 0 11-2 0 1 1 0 012 0z" _fill="#fff" color="#fff"/><mask id="svgicon_learning_a" maskUnits="userSpaceOnUse" x="11" y="6" width="2" height="11"><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M13 6h-2v8h2V6zm0 10a1 1 0 11-2 0 1 1 0 012 0z" _fill="#fff" color="#fff"/></mask>'
  }
})

import Vue from 'vue';
import store from '@store';
import VueRouter from 'vue-router';
import adminRoute from '@admin/router';
import clientRoute from '@client/router';
import middlewarePipeline from '@router/middlewarePipeline';

Vue.use(VueRouter);

const routes = [...adminRoute, ...clientRoute];
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next();
    }

    const { middleware } = to.meta;
    const context = {
        to,
        from,
        next,
        store
    };
    return middleware[0]({
        ...context,
        nextMiddleware: middlewarePipeline(context, middleware, 1)
    });
});

export default router;

import PersonalAccount from '@admin/api/personalAccount';

export const state = {
    schoolReports: [],
    deficits: [],
    canSubscribe: false
};

export const getters = {
    schoolReportsArray: state => {
        if (state.schoolReports.length !== 0) {
            return [state.schoolReports];
        }
        return [];
    },
    deficits: state => state.deficits,
    canSubscribe: state => state.canSubscribe
};

export const mutations = {
    SET_SCHOOL_REPORTS: (state, reports) => {
        state.schoolReports = reports;
    },
    SET_DEFICITS: (state, deficits) => {
        state.deficits = deficits;
    },
    SET_STATUS: (state, canSubscribe) => {
        state.canSubscribe = canSubscribe;
    }
};

export const actions = {
    async getSchoolReports({ commit }) {
        try {
            const reports = await PersonalAccount.getSchoolReports();

            commit('SET_SCHOOL_REPORTS', reports.data);

            return reports.data;
        } catch (e) {
            console.error(e);
        }
    },

    async getDeficits({ commit }) {
        try {
            const deficits = await PersonalAccount.getDeficits();
            const canSubscribe = deficits.data.can_subscribe;

            commit('SET_DEFICITS', deficits.data.deficits);
            commit('SET_STATUS', canSubscribe);

            return deficits.data;
        } catch (e) {
            console.error(e);
        }
    },

    selectProgram({ commit }, params) {
        return PersonalAccount.selectProgram(params);
    },

    getReport({ commit }, params) {
        return PersonalAccount.getReport(params);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

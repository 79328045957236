import Assignments from '@client/api/assignments';

export const state = {
    assignments: [{
        attributes: {
            state: {}
        },
        includes: {
            content: {}
        }
    }],
    assignment: {
        attributes: {
        },
        includes: {
            content: {},
            player_info: {}
        }
    },
    newAssignments: {},
    activeAssignments: {},
    finishedAssignments: {},
    ids: {
        NEW: 0,
        ACTIVE: 1,
        FINISHED: 2
    },
    isOnlyOneNewAndActiveAssignment: false
};

export const getters = {
    assignments: state => state.assignments,
    assignmentById: state => state.assignment,
    newAssignments: state => state.newAssignments,
    activeAssignments: state => state.activeAssignments,
    finishedAssignments: state => state.finishedAssignments,
    assignmentsIds: state => state.ids,
    isOnlyOneNewAndActiveAssignment: state => state.isOnlyOneNewAndActiveAssignment
};

export const mutations = {
    SET_ASSIGNMENTS: (state, assignments) => {
        state.assignments = assignments;
    },
    SET_ASSIGNMENT_STATUS_UPDATE: (state, data) => {
        if (data.status === 'updated') {
            state.assignments = state.assignments.map(item => {
                if (item.id === data.payload.id) {
                    return Object.assign({}, data.payload);
                }
                return item;
            });
        }
        if (data.status === 'deleted') {
            state.assignments = state.assignments.filter(item => item.id !== data.payload.id);
        }
        if (data.status === 'created') {
            state.assignments.push(data.payload);
        }
    },
    SET_ASSIGNMENT_BY_ID: (state, assignment) => {
        state.assignment = assignment;
    },
    SET_NEW_ASSIGNMENTS: (state, assignments) => {
        state.newAssignments = assignments;
    },
    SET_ACTIVE_ASSIGNMENTS: (state, assignments) => {
        state.activeAssignments = assignments;
    },
    SET_FINISHED_ASSIGNMENTS: (state, assignments) => {
        state.finishedAssignments = assignments;
    },
    SET_IS_ONLY_ONE_NEW_AND_ACTIVE_ASSIGNMENT: state => {
        state.isOnlyOneNewAndActiveAssignment = true;
    }
};

export const actions = {
    async requestAssignments({ commit }, params) {
        const res = await Assignments.getAssignments(params);

        let commitType = 'SET_ASSIGNMENTS';

        if (params) {
            if (params.where && params.where.state) {
                switch (params.where.state.value) {
                    case state.ids.NEW:
                        commitType = 'SET_NEW_ASSIGNMENTS';
                        break;

                    case state.ids.ACTIVE:
                        commitType = 'SET_ACTIVE_ASSIGNMENTS';
                        break;

                    case state.ids.FINISHED:
                        commitType = 'SET_FINISHED_ASSIGNMENTS';
                        break;
                }
            }
        }

        commit(commitType, res);

        return res;
    },
    async requestAssignmentById({ commit }, params) {
        const res = await Assignments.getAssignmentById(params);
        commit('SET_ASSIGNMENT_BY_ID', res.data);
        return res.data;
    },
    listenMessages({ rootGetters, commit }) {
        const channel = `listener#${rootGetters['auth/userId']}`;
        rootGetters['ws/server'].on('publish', res => {
            if (res.channel === channel) {
                commit('SET_ASSIGNMENT_STATUS_UPDATE', res.data);
            }
        });
    },
    setIsOnlyOneNewAndActiveAssignment({ commit }) {
        commit('SET_IS_ONLY_ONE_NEW_AND_ACTIVE_ASSIGNMENT');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

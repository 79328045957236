export const state = {
    aliasses: [],
    isLoading: false
};

export const getters = {
    aliasses: state => state.aliasses,
    clientBreadcrumbsLoading: state => state.isLoading
};

export const mutations = {
    SET_ALIASSES: (state, { breadcrumb }) => {
        const breadcrumbAlias = state.aliasses.find(item => item.pattern === breadcrumb.pattern);
        if (breadcrumbAlias) {
            breadcrumbAlias.value = breadcrumb.value;
        } else {
            state.aliasses.push(breadcrumb);
        }
    },
    SET_IS_LOADING: (state, loadingState) => {
        state.isLoading = loadingState;
    }
};

export const actions = {
    setBreadcrumbName({ commit }, { breadcrumb }) {
        commit('SET_ALIASSES', { breadcrumb });
    },
    setBreadcrumbLoading({ commit }, status) {
        commit('SET_IS_LOADING', status);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo_lms': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#45ACE1" fill-rule="evenodd"><path pid="0" d="M17 2.5C17 1.12 18.11 0 19.5 0H22v24h-5V2.5zM9 10.5C9 9.12 10.11 8 11.5 8H14v16H9V10.5zM1 17.507A2.497 2.497 0 013.5 15H6v9H1v-6.493z"/></g>'
  }
})

import TApiConnector from '@global/t-api';

class _Common {
    getRegionList(params) {
        return TApiConnector
            .request('region.index-all', params)
            .then(response => response.data);
    }

    getCityList(params) {
        return TApiConnector
            .request('city.index-all', params)
            .then(response => response.data);
    }

    getSchoolList(params) {
        return TApiConnector
            .request('school.index', params)
            .then(response => response.data);
    }

    getRolesList(params) {
        return TApiConnector
            .request('roles.index', params)
            .then(response => response.data);
    }
}
const Common = new _Common();
export default Common;

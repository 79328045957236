<template>
    <v-menu offset-y :max-height="235">
        <template v-slot:activator="{ on }">
            <v-btn
                v-if="btn"
                :class="['m-primary', { 'red--text': errorMessages.length }]"
                depressed
                color="#E8F3FE"
                v-on="on"
            >
                <v-icon
                    v-if="icon.length"
                    :class="[{ 'red--text': errorMessages.length }]"
                >
                    {{ icon }}
                </v-icon>

                {{ title }}
            </v-btn>

            <v-btn
                v-else
                :class="[{ 'red--text': errorMessages.length }]"
                :disabled="disabled"
                :ripple="false"
                text
                v-on="on"
            >
                <v-icon
                    v-if="icon.length"
                    :class="[{ 'red--text': errorMessages.length }]"
                >
                    {{ icon }}
                </v-icon>

                {{ itemChecked.length > 0 ? itemChecked[0].text : title }}

                <v-icon class="m-expand">expand_more</v-icon>
            </v-btn>
        </template>

        <SidebarMenu>
            <v-list-item
                v-for="type in items"
                :key="type.value"
                class="filter__btn-dropdown__item"
                @click="onFilter(type.value)"
            >
                <v-list-item-title>
                    {{ type.text }}
                </v-list-item-title>

                <v-spacer />

                <v-icon class="m-expand">
                    {{ type.checked ? 'check' : 'i' }}
                </v-icon>
            </v-list-item>
        </SidebarMenu>
    </v-menu>
</template>

<script>
import SidebarMenu from '@components/main/SidebarMenu';

export default {
    name: 'Dropdown',
    components: {
        SidebarMenu
    },
    model: {
        prop: 'select',
        event: 'change'
    },
    props: {
        select: {
            type: Object,
            default: () => ({})
        },
        multiple: {
            type: Boolean,
            default: false
        },
        btn: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => [
                {
                    value: 0,
                    text: 'Item',
                    checked: false
                }
            ]
        },
        title: {
            type: String,
            default: 'Select'
        },
        icon: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            activityById: 'activities/activityById'
        }),
        itemChecked() {
            return this.items.filter(item => item.checked === true);
        }
    },
    methods: {
        onFilter(type) {
            // Для выбора только одного
            if (!this.multiple) {
                this.items.forEach(item => {
                    if (item.value === type) {
                        item.checked = true;
                    } else {
                        item.checked = false;
                    }
                });
                // Для множественного выбора
            } else if (this.multiple) {
                this.items.forEach(item => {
                    if (item.value === type) {
                        item.checked = !item.checked;
                    }
                });
            }
            this.onSelected();
        },
        onSelected() {
            let selectedItems = [];
            selectedItems = this.items.filter(item => item.checked);

            if (selectedItems.length) {
                this.$emit('change', this.multiple ? selectedItems : selectedItems[0]);
            }
        }
    }
};
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/es5/locale/ru';

import preset from '@marmeloy/ts_vue-cli-plugin-vuetify-preset-lp/preset';
// Включение своих svg
import { icons as svgIcons } from 'vue-svgicon';
import '@global/icons';
import SchoolRegistration from '@global/icons/SchoolRegistration';
import SchoolList from '@global/icons/SchoolList';

/*
    Короче, Фронтендер, я тебя от ручной вставки svg спас и в благородство играть не буду:
    увидишь этот код и никому не скажешь — мы в расчете.
    На кой ляд тебе этот файл с vuetify сдался, но я в чужие дела не лезу, хочешь изменить, значит есть зачем.
*/
const myIcons = {};
Object.keys(svgIcons).forEach(key => {
    myIcons[key] = {
        component: 'svgicon',
        props: {
            name: key
        }
    };
});

myIcons.school_registration = {
    component: SchoolRegistration,
    props: {
        name: 'school_registration'
    }
};

myIcons.school_list = {
    component: SchoolList,
    props: {
        name: 'school_list'
    }
};

Vue.use(Vuetify);

const options = {
    icons: {
        values: myIcons
    },
    lang: {
        locales: { ru },
        current: 'ru'
    },
    theme: {
        themes: {
            light: {
                primary: '#612CB0',
                secondary: '#eff0f5',
                accent: '#7fdd36'
            }
        }
    },
    breakpoint: {
        thresholds: {
            md: 1383
        }
    }
};

const vuetify = new Vuetify({
    preset,
    ...options
});

export default vuetify;

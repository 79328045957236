<template>
    <div class="error-shapes">
        <Shape
            v-for="(shape, i) in defaultShapes"
            :key="i"
            :options="shape"
        />
    </div>
</template>

<script>
import Shape from '@components/ui/Shape';

export default {
    name: 'ErrorShape',
    components: {
        Shape
    },
    computed: {
        defaultShapes() {
            return [
                {
                    styles: {
                        bottom: 0,
                        left: 0,
                        width: '108px',
                        height: '54px',
                        borderRadius: '54px 54px 0 0',
                        backgroundColor: this.$vuetify.theme.themes.light.secondary,
                        transform: 'rotate(-30deg) translateY(-26px)'
                    }
                },
                {
                    styles: {
                        bottom: 0,
                        right: 'calc(79px + 10px)',
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        backgroundColor: this.$vuetify.theme.themes.light.secondary
                    }
                },
                {
                    styles: {
                        bottom: 0,
                        right: 0,
                        width: '79px',
                        height: '38px',
                        borderRadius: '0 38px 0 0',
                        backgroundColor: this.$vuetify.theme.themes.light.secondary
                    }
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
.error-shapes {
    position: relative;
    width: 186px;
    height: 82px;
}
</style>

import TApiConnector from '@global/t-api';

class _Users {
    getUsersList(params) {
        return TApiConnector
            .request('users.index', params)
            .then(response => response.data);
    }

    getUser(params) {
        return TApiConnector
            .request('users.show', params)
            .then(response => response.data);
    }

    createUser(data) {
        return TApiConnector
            .request('users.store', null, null, null, data)
            .then(response => response.data);
    }

    updateUser(params, data) {
        return TApiConnector
            .request('users.update', params, null, null, data)
            .then(response => response.data);
    }

    deleteUser(params) {
        return TApiConnector
            .request('users.destroy', params)
            .then(response => response.data);
    }

    assembleRoles(params) {
        return TApiConnector
            .request('roles.index', params)
            .then(response => response.data);
    }

    downloadUsers() {
        return TApiConnector
            .request('users.list.download')
            .then(response => response.data);
    }

    uploadUsers(data) {
        return TApiConnector
            .request('users.list.upload', null, null, null, data)
            .then(response => response.data);
    }

    getUsersTemplate() {
        return TApiConnector
            .request('users.list.upload.format')
            .then(response => response.data);
    }

    createTokens(data) {
        return TApiConnector
            .request('tokens.generate', null, null, null, data)
            .then(response => response.data);
    }

    downloadTokens() {
        return TApiConnector
            .request('tokens.list.download')
            .then(response => response.data);
    }
}
const Users = new _Users();
export default Users;

import TApiConnector from '@global/t-api';

class _Ws {
    getConfig() {
        return TApiConnector
            .request('ws.config')
            .then(responce => responce.data);
    }
}

const Ws = new _Ws();
export default Ws;

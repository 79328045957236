import Vue from 'vue';
import Vuex from 'vuex';

import globals from '@store/modules/globals';
import snackbar from '@store/modules/snackbar';

import client from '@client/store';
import admin from '@admin/store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        globals,
        snackbar,
        ...client,
        ...admin
    }
});

import guest from '@router/middleware/guest';
import auth from '@router/middleware/auth';
import logout from '@router/middleware/logout';
import Error404 from '@client/views/errors/Error404.vue';

const AuthView = () => import('@client/views/AuthView.vue');
const MainView = () => import('@client/views/MainView.vue');
const ContentView = () => import('@client/components/contents/views/ContentView.vue');
const AssignmentView = () => import('@client/components/assignments/views/AssignmentView.vue');

// для директора
const Main = () => import('@admin/views/Main.vue');
const SchoolInfo = () => import('@client/components/director/views/SchoolInfo.vue');
const SchoolTeachers = () => import('@client/components/director/views/SchoolTeachers.vue');
const MySchoolTeacher = () => import('@client/components/director/views/MySchoolTeacher.vue');

// для руководителя директоров
const SchoolsList = () => import('@client/components/chief/views/SchoolsList.vue');
const SchoolReports = () => import('@client/components/chief/views/SchoolReports.vue');
const MyRegion = () => import('@client/components/chief/views/MyRegion.vue');

// для администратора заказчика
const CustomerTeachers = () => import('@client/components/customer/views/CustomerTeachers.vue');

// для администратора исполнителя
const SchoolsTable = () => import('@client/components/executor/views/SchoolsTable.vue');
const SchoolDetails = () => import('@client/components/executor/components/SchoolDetails.vue');
const TeachersList = () => import('@client/components/executor/views/TeachersList.vue');

// для педагога
const PersonalAccount = () => import('@admin/components/personal-account/views/PersonalAccount.vue');

// для наставника
const TutorTeachersList = () => import('@client/components/tutor/views/TutorTeachersList.vue');
const TutorTeacherReport = () => import('@client/components/tutor/views/TutorTeacherReport.vue');

export default [
    // isNotAuth
    {
        path: '/auth',
        name: 'auth',
        component: AuthView,
        meta: { middleware: [guest] }
    },
    //  isAuth
    {
        path: '/',
        component: MainView,
        children: [
            {
                path: '',
                name: 'assignments',
                component: AssignmentView,
                meta: { middleware: [auth] }
            },
            {
                path: 'assignment/:id',
                name: 'assignment',
                component: ContentView,
                meta: { middleware: [auth] }
            }
        ]
    },
    {
        path: '/my-school-info',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Моя образовательная организация'
            }
        },
        children: [
            {
                path: '/',
                name: 'my-school-info',
                component: SchoolInfo,
                meta: { middleware: [auth] }
            }
        ]
    },
    {
        path: '/my-school-teachers',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Список педагогов'
            }
        },
        children: [
            {
                path: '',
                name: 'my-school-teachers',
                component: SchoolTeachers,
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: ':teacherId',
                name: 'my-teacher',
                component: MySchoolTeacher,
                meta: {
                    middleware: [auth],
                    breadcrumbs: {
                        name: 'ФИО'
                    }
                }
            }
        ]
    },
    {
        path: '/school-registration',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Регистрация образовательной организации'
            }
        },
        children: [
            {
                path: '',
                name: 'executor-schools-table',
                component: SchoolsTable,
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'create',
                name: 'executor-school-create',
                component: SchoolDetails,
                meta: {
                    middleware: [auth],
                    breadcrumbs: {
                        name: 'Регистрация образовательной организации'
                    }
                }
            },
            {
                path: ':schoolId',
                name: 'executor-school-details',
                component: SchoolDetails,
                meta: {
                    middleware: [auth],
                    breadcrumbs: {
                        name: 'Редактирование образовательной организации'
                    }
                }
            }
        ]
    },
    {
        path: '/my-schools-list',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Список образовательных организаций'
            }
        },
        children: [
            {
                path: '',
                name: 'my-schools-list',
                component: SchoolsList,
                meta: { middleware: [auth] }
            },
            {
                path: ':schoolId',
                name: 'school-reports',
                component: SchoolReports,
                meta: {
                    middleware: [auth],
                    breadcrumbs: {
                        name: 'Образовательная организация'
                    }
                }
            }
        ]
    },
    {
        path: '/teachers-list',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Список педагогов'
            }
        },
        children: [
            {
                path: '',
                name: 'school-teachers',
                component: TeachersList,
                meta: { middleware: [auth] }
            },
            {
                path: ':schoolId',
                name: 'executor-teachers',
                component: TeachersList,
                meta: {
                    middleware: [auth]
                },
                breadcrumbs: {
                    name: '{{schoolName}}'
                }
            }
        ]
    },
    {
        path: '/my-region',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Мой регион'
            }
        },
        children: [
            {
                path: '',
                name: 'my-region',
                component: MyRegion,
                meta: {
                    middleware: [auth]
                }
            }
        ]
    },
    {
        path: '/customer-teachers',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Список педагогов',
                disabled: true
            }
        },
        children: [
            {
                path: '',
                name: 'customer-teachers',
                component: CustomerTeachers,
                meta: {
                    middleware: [auth]
                }
            }
        ]
    },
    {
        path: '/profile',
        component: Main,
        meta: {
            breadcrumbs: {
                name: 'Отчёты'
            }
        },
        children: [
            {
                path: '',
                name: 'profile',
                component: PersonalAccount,
                meta: {
                    middleware: [auth]
                }
            }
        ]
    },
    {
        path: '/my-teachers-list',
        component: Main,
        meta: {
            middleware: [auth],
            breadcrumbs: {
                name: 'Список курируемых пользователей'
            }
        },
        children: [
            {
                path: '',
                name: 'my-teachers-list',
                component: TutorTeachersList,
                meta: { middleware: [auth] }
            },
            {
                path: ':teacherId',
                name: 'check-teacher',
                component: TutorTeacherReport,
                meta: {
                    middleware: [auth]
                },
                breadcrumbs: {
                    name: 'ФИО'
                }
            }
        ]
    },
    {
        path: '/logout',
        name: 'logout',
        component: AuthView,
        meta: { middleware: [auth, logout] }
    },
    {
        path: '*',
        name: '404',
        component: Error404,
        meta: { middleware: [auth] }
    }
];

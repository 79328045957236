<template>
    <div class="error-page border-bottom white">
        <div class="error-image mb-12">
            <slot v-if="image" name="image" />
            <template v-else>
                <ErrorShape />
            </template>
        </div>
        <div class="error-content">
            <h1 class="error-content__title mb-4">
                {{ errorNumber }}
            </h1>
            <div class="error-content__text">
                <slot name="report" />
            </div>
        </div>
    </div>
</template>

<script>
import ErrorShape from '@components/error/ErrorShape';

export default {
    name: 'ErrorPage',
    components: {
        ErrorShape
    },
    props: {
        title: {
            type: String,
            required: true
        },
        errorNumber: {
            type: String,
            required: true
        },
        image: {
            type: Object
        }
    },
    computed: {
        defaultShapes() {
            return [
                {
                    styles: {
                        bottom: 0,
                        left: 0,
                        width: '108px',
                        height: '54px',
                        borderRadius: '54px 54px 0 0',
                        backgroundColor: this.$vuetify.theme.themes.light.secondary,
                        transform: 'rotate(-30deg) translateY(-26px)'
                    }
                },
                {
                    styles: {
                        bottom: 0,
                        right: 'calc(79px + 10px)',
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        backgroundColor: this.$vuetify.theme.themes.light.secondary
                    }
                },
                {
                    styles: {
                        bottom: 0,
                        right: 0,
                        width: '79px',
                        height: '38px',
                        borderRadius: '0 38px 0 0',
                        backgroundColor: this.$vuetify.theme.themes.light.secondary
                    }
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.error-image {
    position: relative;
    width: 186px;
    height: 82px;
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    &__title {
        font-weight: 900;
        font-size: 24px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>

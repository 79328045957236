export const state = {
    snackbarQueue: [],
    currentSnackbar: false
};

export const getters = {
    snackbarQueue: state => state.snackbarQueue,
    currentSnackbar: state => state.currentSnackbar,
    currentSnackbarStatus: (state, { currentSnackbar }) => {
        if (currentSnackbar) {
            return state.currentSnackbar.status;
        }

        return false;
    }
};

export const mutations = {
    ADD_SNACKBAR: (state, data) => {
        if (state.currentSnackbar) {
            state.snackbarQueue.push(data);
        } else {
            state.currentSnackbar = data;
        }
    },
    CLOSE_SNACKBAR: state => {
        state.currentSnackbar.status = false;
    },
    SET_NEXT_SNACKBAR: state => {
        if (state.snackbarQueue.length > 0) {
            state.currentSnackbar = state.snackbarQueue[0];
            state.snackbarQueue.splice(0, 1);
        } else {
            state.currentSnackbar = false;
        }
    }
};

export const actions = {
    addSnackbar: ({ commit }, data) => {
        let formattedData = {};
        formattedData.status = true;
        if (data) {
            const { type } = data;
            switch (type) {
                case 'default':
                    formattedData.timeout = 5000;
                    formattedData.title = 'Упс, что-то пошло не так';
                    formattedData.text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
                    formattedData.level = 'warning';
                    break;
                case 'connectionError':
                    formattedData.timeout = 5000;
                    formattedData.title = 'Ошибка интернет-соединения';
                    formattedData.text = 'На последний запрос пришла ошибка, этот текст вообще желательно поменять';
                    formattedData.level = 'error';
                    break;
                case 'custom':
                    formattedData = { ...formattedData, ...data };
                    break;
            }
        }
        commit('ADD_SNACKBAR', formattedData);
    },
    closeSnackbar: ({ getters, commit }) => {
        if (getters.currentSnackbarStatus) {
            commit('CLOSE_SNACKBAR');
            // TODO выпилить костыль с сеттаймаутом или синхронизировать мутации
            setTimeout(() => {
                commit('SET_NEXT_SNACKBAR');
            }, 400);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

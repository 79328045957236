import TApiConnector from '@global/t-api';

class _PersonalAccount {
    getSchoolReports() {
        return TApiConnector
            .request('report.listener.characteristics.my')
            .then(response => response.data);
    }

    getDeficits() {
        return TApiConnector
            .request('report.listener.identified-deficits.my')
            .then(response => response.data);
    }

    selectProgram(params) {
        return TApiConnector
            .request('extra-course.want', params)
            .then(response => response.data);
    }

    getReport(params) {
        return TApiConnector
            .request('report.listener.characteristic.my', params)
            .then(response => response.data);
    }
}
const PersonalAccount = new _PersonalAccount();
export default PersonalAccount;

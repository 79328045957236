<template>
    <div>
        <div
            v-if="type === 'image'"
            class="preview mt-2"
        >
            <div
                v-for="(file, i) in files"
                :key="i"
                class="preview__img"
            >
                <img
                    :src="file.attributes.url"
                    alt="image"
                >

                <div
                    v-if="!disabled"
                    class="preview__hover"
                >
                    <div class="preview-close__block">
                        <v-icon class="red--text preview-close__icon" @click="removeFile(i)">close</v-icon>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="type === 'file'">
            <div
                v-for="(file, i) in files"
                :key="i"
                class="d-flex flex-wrap align-center"
            >
                <p class="mb-0 mr-2">{{ file.attributes.name }}</p>
                <p class="mb-0 mr-2">
                    <span class="file__format mr-2">{{ file.attributes.type }}</span>
                    {{ file.attributes.size }} KB
                </p>
                <v-icon
                    class="notranslate"
                    :class="{ 'red--text': !disabled }"
                    @click="removeFile(i)"
                >delete</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Preview',
    model: {
        prop: 'files',
        event: 'change'
    },
    props: {
        files: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'file'
        }
    },
    methods: {
        removeFile(index) {
            if (!this.disabled) {
                this.files.splice(index, 1);
                this.$emit('change', this.files);
            }
        }
    }
};
</script>
<style lang="scss">
    .block {
        display: block !important;
    }

    .file {
        &__format {
            display: inline-flex;
            padding: 3px 5px;
            color: #ffffff;
            background-color: #000000;
            border-radius: 5px;
        }
    }

    .preview {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100%;

        &__hover:hover {
            display: block;
            background-color: rgba(0, 0, 0, 0.325);
            transition: .2s;
        }

        &__hover {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0; left: 0; bottom: 0; right: 0;
            border-radius: 5px;
        }

        &-close {
            &__block {
                display: flex;
                height: 100%;
                justify-content: center;
                align-content: center;
                align-items: center;
                border-radius: 5px;
            }

            &__icon {
                font-size: 40px !important;
                cursor: pointer;
            }

            &__icon:hover {
                font-size: 50px !important;
            }
        }

        &__img {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 250px;

            img {
                border-radius: 5px;
                width: 250px;
                min-height: 100px;
                object-fit: contain;
            }

            img:hover ~ .preview__hover {
                transition: 10s;
                display: block;
            }
        }
    }
</style>

import store from '@store';

const getTextContent = (value, snackbar = {}) => {
    const textarea = document.createElement('textarea');
    textarea.value = value;

    // Avoid scrolling to bottom
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    let {
        title = '',
        text = '',
        level = ''
    } = snackbar;

    try {
        document.execCommand('copy');
        title = 'Успешно!';
        text = 'Данные скопированы';
        level = 'success';
    } catch (e) {
        console.error(e);
        title = 'Ошибка!';
        text = 'Что-то пошло не так';
        level = 'error';
    }

    if (snackbar && !store.getters['snackbar/currentSnackbarStatus']) {
        store.dispatch('snackbar/addSnackbar', {
            type: 'custom',
            timeout: 5000,
            title,
            text,
            level
        });
    }

    document.body.removeChild(textarea);
};
let clickHandler;

export default {
    bind(el, binding) {
        clickHandler = e => {
            const value = binding.value !== undefined
                ? binding.value
                : e.target.textContent;

            getTextContent(value);
        };

        el.addEventListener('click', clickHandler);
    },
    unbind(el) {
        el.removeEventListener('click', clickHandler);
    }
};

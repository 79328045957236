/* eslint-disable */
require('@global/icons/burger')
require('@global/icons/learning')
require('@global/icons/logo_lms')
require('@global/icons/logout')
require('@global/icons/error_403')
require('@global/icons/error_404')
require('@global/icons/error_500')
require('@global/icons/success_tick')
require('@global/icons/logo_palms')
// require('@global/icons/logo_mcrkpo')
require('@global/icons/logo_mgpu')

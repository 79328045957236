<template>
    <v-layout
        column
        :my-3="inTable"
    >
        <v-icon class="notranslate" color="#ffd972">info</v-icon>
        <div class="body-1 body-1--light text-xs-center">{{ noDataText }}</div>
    </v-layout>
</template>

<script>
export default {
    name: 'NoDataInTable',
    props: {
        noDataText: {
            type: String,
            default: () => 'Нет данных'
        },
        inTable: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss" scoped>
.layout {
    overflow-x: hidden;
}
</style>

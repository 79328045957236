import Activities from '@admin/api/activities';

export const state = {
    activities: {
        data: [],
        meta: {}
    },
    activityById: {
        attributes: {},
        includes: {
            archive: [],
            cover: []
        }
    },
    activityUsers: {
        data: [],
        meta: {}
    },
    loadedSize: {}
};

export const getters = {
    activities: state => state.activities,
    activityById: state => state.activityById,
    activityUsers: state => state.activityUsers,
    loadedSize: state => state.loadedSize
};

export const mutations = {
    SET_ACTIVITIES_LIST: (state, activities) => {
        state.activities = activities;
    },
    SET_ACTIVITY_BY_ID: (state, activity) => {
        state.activityById = activity;
    },
    SET_ACTIVITY_USERS_LIST: (state, users) => {
        state.activityUsers = users;
    },
    SET_SIZE_LOADED: (state, { loaded, total }) => {
        state.loadedSize = { loaded, total };
    }
};

export const actions = {
    clearActivity({ commit }) {
        commit('SET_ACTIVITY_BY_ID', {
            attributes: {},
            includes: {
                archive: [],
                cover: []
            }
        });
    },
    clearActivities({ commit }) {
        commit('SET_ACTIVITIES_LIST', {
            data: [],
            meta: {}
        });
    },
    getActivities({ commit }, params) {
        return Activities.getActivitiesList(params).then(res => {
            commit('SET_ACTIVITIES_LIST', res);
            return res.data;
        });
    },
    getAllActivities({ commit }, params) {
        return Activities.getAllActivitiesList(params).then(res => {
            commit('SET_ACTIVITIES_LIST', res);
            return res.data;
        });
    },
    getActivityDetails({ commit }, params) {
        return Activities.getActivity(params).then(res => {
            commit('SET_ACTIVITY_BY_ID', res.data);
        });
    },
    getActivityUsers({ commit }, params) {
        return Activities.getActivityUsersList(params).then(res => {
            commit('SET_ACTIVITY_USERS_LIST', res);
        });
    },
    updateActivity({ commit }, { params, data }) {
        return Activities.updateActivity(params, data);
    },
    createActivity({ commit }, data) {
        return Activities.createActivity(data);
    },
    deleteActivity({ commit }, params) {
        return Activities.deleteActivity(params);
    },
    uploadActivityFile({ dispatch }, data) {
        const config = {
            onUploadProgress: progressEvent => dispatch('setLoadedSize', progressEvent)
        };
        return Activities.uploadActivityFile(data, config);
    },
    uploadActivityFileCover({ dispatch }, data) {
        const config = {
            onUploadProgress: progressEvent => dispatch('setLoadedSize', progressEvent)
        };
        return Activities.uploadActivityFileCover(data, config);
    },
    setLoadedSize({ commit }, { loaded, total }) {
        commit('SET_SIZE_LOADED', { loaded, total });
    },
    downloadUsersResults({ commit }, params) {
        return Activities.downloadUsersResults(params);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 6V1h15v22H8v-3l2-2v3h4V7l6-4H10v5L8 6zm-3 4l2-2 5 5-5 5-2-2 2-2H1v-2h6l-2-2z" _fill="#45ACE1"/><mask id="svgicon_logout_a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M8 6V1h15v22H8v-3l2-2v3h4V7l6-4H10v5L8 6zm-3 4l2-2 5 5-5 5-2-2 2-2H1v-2h6l-2-2z" _fill="#fff"/></mask>'
  }
})

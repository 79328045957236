import Users from '@admin/api/users';

export const state = {
    users: {
        data: [],
        meta: {}
    },
    userById: {
        attributes: {
            roles: [
                2
            ]
        }
    },
    roles: [],
    template: {}
};

export const getters = {
    users: state => state.users,
    userById: state => state.userById,
    roles: state => state.roles,
    isUserLoaded: state => !!state.userById.id,
    template: state => state.template
};

export const mutations = {
    SET_USER_ROLE: (state, roleId) => {
        state.userById.attributes.roles[0] = roleId;
    },
    SET_USERS_LIST: (state, users) => {
        state.users = users;
    },
    SET_USER_BY_ID: (state, user) => {
        state.userById = user;
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    SET_TEMPLATE: (state, template) => {
        state.template = template;
    }
};

export const actions = {
    clearUser({ commit }) {
        commit('SET_USER_BY_ID', {
            attributes: {
                roles: [
                    2
                ]
            }
        });
    },
    clearUsers({ commit }) {
        commit('SET_USERS_LIST', {
            data: [],
            meta: {}
        });
    },
    async getUsers({ commit }, params) {
        try {
            const users = await Users.getUsersList(params);

            commit('SET_USERS_LIST', users);

            return users;
        } catch (e) {
            console.error(e);
        }
    },
    async getUserDetails({ commit }, params) {
        try {
            const user = await Users.getUser(params);
            user.data.attributes.roles = user.data.attributes.roles.map(role => role.id);

            commit('SET_USER_BY_ID', user.data);

            return user.data;
        } catch (e) {
            console.error(e);
        }
    },
    updateUser({ commit }, { params, data }) {
        return Users.updateUser(params, data);
    },
    createUser({ commit }, data) {
        return Users.createUser(data);
    },
    deleteUser({ commit }, params) {
        return Users.deleteUser(params);
    },
    updateCurrentUserRole({ commit, getters }, roleId) {
        const userRoles = getters.userById.attributes.roles;
        if (userRoles && userRoles.length > 0) {
            if (!userRoles.includes(roleId)) {
                commit('SET_USER_ROLE', roleId);
            }
        } else {
            commit('SET_USER_ROLE', roleId);
        }
    },
    async assembleRoles({ commit }, params) {
        const res = await Users.assembleRoles(params);
        commit('SET_ROLES', res);
    },
    downloadUsers() {
        return Users.downloadUsers();
    },
    uploadUsers({ commit }, data) {
        return Users.uploadUsers(data);
    },
    async getUsersTemplate({ commit }) {
        const { data } = await Users.getUsersTemplate();
        commit('SET_TEMPLATE', data);
    },
    createTokens({ commit }, data) {
        return Users.createTokens(data);
    },
    downloadTokens() {
        return Users.downloadTokens();
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

import Common from '@admin/api/common';

export const state = {
    regions: {
        data: [],
        meta: {}
    },
    cities: {
        data: [],
        meta: {}
    },
    schools: {
        data: [],
        meta: {}
    },
    roles: {
        data: [],
        meta: {}
    }
};

export const getters = {
    regions: state => state.regions,
    cities: state => state.cities,
    schools: state => state.schools,
    roles: state => state.roles
};

export const mutations = {
    SET_REGION_LIST: (state, regions) => {
        state.regions = regions;
    },
    SET_CITY_LIST: (state, cities) => {
        state.cities = cities;
    },
    SET_SCHOOL_LIST: (state, schools) => {
        state.schools = schools;
    },
    SET_ROLES_LIST: (state, roles) => {
        state.roles = roles;
    }
};

export const actions = {
    getRegions({ commit }, params) {
        return Common.getRegionList(params).then(res => {
            commit('SET_REGION_LIST', res);
        });
    },
    getCities({ commit }, params) {
        return Common.getCityList(params).then(res => {
            commit('SET_CITY_LIST', res);
        });
    },
    getSchools({ commit }, params) {
        return Common.getSchoolList(params).then(res => {
            commit('SET_SCHOOL_LIST', res);
        });
    },
    getRoles({ commit }, params) {
        return Common.getRolesList(params).then(res => {
            commit('SET_ROLES_LIST', res);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

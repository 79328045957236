import TApiConnector from '@global/t-api';

class _Auth {
    login(params) {
        return TApiConnector
            .request('auth.login', null, null, null, params)
            .then(response => response.data);
    }

    registration(params) {
        return TApiConnector
            .request('auth.register', null, null, null, params)
            .then(response => response.data);
    }

    recover(params) {
        return TApiConnector
            .request('auth.recover', null, null, null, params)
            .then(response => response.data);
    }

    logout() {
        return TApiConnector
            .request('auth.logout')
            .then(responce => responce.data);
    }

    getUser(params) {
        return TApiConnector
            .request('auth.me', params)
            .then(response => response.data);
    }

    isTokenRequired() {
        return TApiConnector
            .request('auth.auth-token-required')
            .then(response => response);
    }
}

const Auth = new _Auth();
export default Auth;

import TApiConnector from '@global/t-api';

class _Assignments {
    getAssignments(params) {
        return TApiConnector
            .request('my-assignments.index', params)
            .then(response => response.data);
    }

    getAssignmentById(params) {
        return TApiConnector
            .request('my-assignments.show', params)
            .then(response => response.data);
    }
}

const Assignments = new _Assignments();
export default Assignments;

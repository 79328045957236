import 'intersection-observer';
import Vue from 'vue';
import App from './App.vue';

import router from '@router';
import store from '@store';

import '@global/globals';

import vuetify from '@plugins/vuetify';

// Loader and Progress management
import VueWait from 'vue-wait';

import '@styles/_customTheme.scss';

import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import highchartsMore from 'highcharts/highcharts-more';

Vue.config.productionTip = false;

Vue.use(VueWait);

Vue.use(HighchartsVue);
highchartsMore(Highcharts);

new Vue({
    router,
    store,
    vuetify,
    wait: new VueWait({ useVuex: true }),
    render: h => h(App)
}).$mount('#app');

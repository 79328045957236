export default async ({ next, store, nextMiddleware }) => {
    if (!store.getters['auth/userId']) {
        if (!store.getters['auth/isUserRequested']) {
            await store.dispatch('auth/requestUser');
        }
    }

    if (store.getters['auth/isUserAuth']) {
        return next({ name: 'assignments' });
    }

    return nextMiddleware();
};

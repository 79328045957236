import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const state = () => ({
    isSidebarOpened: {
        large: true,
        laptop: false,
        mobile: false
    },
    error: {
        active: false,
        type: '',
        types: [
            '401',
            '403',
            '404',
            '500',
            '503'
        ]
    },
    search: {
        isOpened: false,
        resultsEl: null
    },
    scrollbarWidth: 0,
    isContentPage: false,
    isPageLoaded: false
});

const getters = {
    isSidebarOpened: state => state.isSidebarOpened,
    isError: state => state.error.active,
    errorType: state => state.error.type,
    errorTypes: state => state.error.types,
    isSearchOpened: state => state.search.isOpened,
    searchResultsEl: state => state.search.resultsEl,
    scrollbarWidth: state => state.scrollbarWidth,
    isContentPage: state => state.isContentPage,
    isPageLoaded: state => state.isPageLoaded
};

const mutations = {
    TOGGLE_SIDEBAR: (state, type) => {
        state.isSidebarOpened[type] = !state.isSidebarOpened[type];

        // TODO: Когда будет время, нужно переписать сайдбар
        const resizeEvent = () => {
            if (typeof (Event) === 'function') {
                window.dispatchEvent(new Event('resize'));
            } else {
                const evt = window.document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
            }
        };

        if (type === 'large') {
            setTimeout(() => resizeEvent, 500);
        }
    },
    OPEN_SIDEBAR: (state, type) => {
        state.isSidebarOpened[type] = true;
    },
    CLOSE_SIDEBAR: (state, type) => {
        state.isSidebarOpened[type] = false;
    },
    SET_ERROR: (state, type) => {
        state.error.active = true;

        if (state.error.types.includes(type)) {
            state.error.type = type;
        } else {
            state.error.type = '500';
        }
    },
    REMOVE_ERROR: state => {
        state.error.active = false;
        state.error.type = '';
    },
    OPEN_SEARCH: state => {
        state.search.isOpened = true;
    },
    CLOSE_SEARCH: state => {
        state.search.isOpened = false;
    },
    SET_SEARCH_RESULTS_ELEMENT: (state, el) => {
        state.search.resultsEl = el;
    },
    SET_SCROLLBAR_WIDTH: (state, width) => {
        state.scrollbarWidth = width;
    },
    SET_PAGE_IS_CONTENT: state => {
        state.isContentPage = true;
    },
    SET_PAGE_IS_NOT_CONTENT: state => {
        state.isContentPage = false;
    },
    SET_PAGE_IS_LOADED: state => {
        state.isPageLoaded = true;
    },
    SET_PAGE_IS_NOT_LOADED: state => {
        state.isPageLoaded = false;
    }
};

const actions = {
    setError: ({ commit }, type) => {
        commit('SET_ERROR', type);
    },
    removeError: ({ commit }) => {
        commit('REMOVE_ERROR');
    },
    openSearch: ({ commit, getters }) => {
        commit('OPEN_SEARCH');
        disableBodyScroll(getters.searchResultsEl, {
            reserveScrollBarGap: true
        });
    },
    closeSearch: ({ commit }) => {
        commit('CLOSE_SEARCH');
        clearAllBodyScrollLocks();
    },
    setSearchResultsElement: ({ commit }, el) => {
        commit('SET_SEARCH_RESULTS_ELEMENT', el);
    },
    setScrollbarWidth: ({ commit }, width) => {
        commit('SET_SCROLLBAR_WIDTH', width);
    },
    setPageIsContent: ({ commit }) => {
        commit('SET_PAGE_IS_CONTENT');
    },
    setPageIsNotContent: ({ commit }) => {
        commit('SET_PAGE_IS_NOT_CONTENT');
    },
    setPageIsLoaded: ({ commit }) => {
        commit('SET_PAGE_IS_LOADED');
    },
    setPageIsNotLoaded: ({ commit }) => {
        commit('SET_PAGE_IS_NOT_LOADED');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

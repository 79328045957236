import TApiConnector from '@global/t-api';

class _Structure {
    getProgramStructure(params) {
        return TApiConnector
            .request('program.structure.show', params)
            .then(res => res.data);
    }

    saveProgramStructure(params, data) {
        return TApiConnector
            .request('program.structure.store', params, null, null, data)
            .then(res => res.data);
    }
}

const Structure = new _Structure();
export default Structure;

/* eslint-disable */
const icon = require('vue-svgicon')
icon.register({
  'success_tick': {
    viewBox: '0 0 33 30',
    data: `
    <g id="icon">
        <path d="M29.7876 9.17442C30.5685 10.9659 31 12.9338 31 15.0004C31 23.2849 24.0602 30 15.4996 30C6.93979 30 0 23.2849 0 15.0004C0 6.71592 6.93979 0 15.4996 0C19.2156 0 22.626 1.26529 25.2958 3.37495" id="Stroke-1" fill="none" stroke="#5FBEFE" stroke-width="1" stroke-linejoin="round" />
        <path d="M0 14.6728L7.62347 23L26 0" transform="translate(7 0)" id="Stroke-3" fill="none" stroke="#5FBEFE" stroke-width="1" stroke-linejoin="round" />
    </g>`
  }
});

import TApiConnector from '@global/t-api';

class _schoolRegistration {
    getSchool(params) {
        return TApiConnector
            .request('school.show', params)
            .then(response => response.data);
    }

    createSchool(params) {
        return TApiConnector
            .request('school.store', null, null, null, params)
            .then(response => response.data);
    }

    updateSchool(params, data) {
        return TApiConnector
            .request('school.update', params, null, null, data)
            .then(response => response.data);
    }

    deleteSchool(params) {
        return TApiConnector
            .request('school.destroy', params)
            .then(response => response.data);
    }
}
const schoolRegistration = new _schoolRegistration();
export default schoolRegistration;

import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import VClamp from 'vue-clamp';
import SvgIcon from 'vue-svgicon';
import store from '@store';

import TApiConnector from '@global/t-api';

import Dropdown from '@components/ui/Dropdown.vue';
import FileUpload from '@components/ui/FileUpload.vue';
import kmFile from '@components/ui/kmFile';
import kmSkeletonTable from '@components/ui/skeletons/kmSkeletonTable';
import kmNoDataInTable from '@components/ui/kmNoDataInTable';
import Preview from '@components/ui/Preview';
import Preloader from '@components/ui/Preloader';
import Confirm from '@components/modals/Confirm.vue';

import vFocus from '@global/directives/focus';
import vCopyOnClick from '@global/directives/copy-on-click';

TApiConnector.setSettings({
    requestSettings: {
        timeout: 900000
    }
});

Vue.use(SvgIcon, {
    tagName: 'svgicon',
    defaultWidth: '24px',
    defaultHeight: '24px'
});

const onError = error => {
    const res = error.response;

    if (res) {
        switch (res.status) {
            case 401:
                if (store.getters['auth/isUserAuth']) {
                    store.dispatch('auth/removeUserSession');
                }

                store.dispatch('globals/setError', '401');
                break;
            case 403:
                store.dispatch('globals/setError', '403');
                break;
            case 404:
                store.dispatch('globals/setError', '404');
                break;
            case 422:
                break;
            case 503:
                store.dispatch('globals/setError', '503');
                break;
            default:
                store.dispatch('globals/setError', '500');
                break;
        }
    }

    if (error.message === 'Network Error') {
        if (!store.getters['snackbar/currentSnackbarStatus']) {
            store.dispatch('snackbar/addSnackbar', {
                type: 'custom',
                timeout: 0,
                title: 'Нет интернет-подключения',
                text: 'Последние действия были сохранены. Для продолжения работы проверьте соединение',
                level: 'error'
            });
        }
    }

    return Promise.reject(error);
};

console.log(process.env.NODE_ENV);

TApiConnector.withCredentials();
TApiConnector.setInterceptors({ onError });
TApiConnector.requestRoutesWithPromise();

if (IS_PRODUCTION) {
    try {
        Sentry.init({
            dsn: 'https://55b8a58af4be41fc98fc63059daaa54d@sentry.devspark.ru/17',
            integrations: [new VueIntegration({ Vue, attachProps: true })],
            environment: IS_PRODUCTION ? 'production' : 'development'
        });
    } catch (e) {
        console.error(e);
    }
}

Vue.component('v-clamp', VClamp);
Vue.component('kmFile', kmFile);
Vue.component('km-skeleton-table', kmSkeletonTable);
Vue.component('km-no-data', kmNoDataInTable);
Vue.component('Dropdown', Dropdown);
Vue.component('FileUpload', FileUpload);
Vue.component('Confirm', Confirm);
Vue.component('Preview', Preview);
Vue.component('Preloader', Preloader);

Vue.directive('focus', vFocus);
Vue.directive('copy-on-click', vCopyOnClick);

import Reports from '@admin/api/reports';

export const state = {
    reportsEnabled: REPORTS_ENABLED,
    isReportsOpened: false,
    reports: {
        data: [],
        meta: {}
    },
    reportStates: {
        QUEUED: 0,
        IN_PROGRESS: 1,
        COMPLETED: 2,
        ERROR: 3
    },
    reportTypes: {
        EXPORT_USERS: 'export_users',
        IMPORT_USERS: 'import_users',
        EXPORT_USERS_BY_CONTENT: 'export_users_by_content',
        EXPORT_USERS_BY_PROGRAM: 'export_users_by_program',
        EXPORT_USERS_BY_ASSIGNMENT: 'export_users_by_pack',
        ASSIGN_USERS_TO_CONTENT: 'assign_users_to_content',
        EXPORT_USERS_TOKENS: 'export_users_tokens'
    },
    reportsLoadingIcons: new Map([
        [
            'queued',
            {
                icon: 'mdi-human-queue',
                color: 'rgba(0, 0, 0, 0.5)'
            }
        ],
        [
            'in_progress',
            {
                icon: 'mdi-progress-clock',
                color: 'primary'
            }
        ],
        [
            'completed',
            {
                icon: 'mdi-check',
                color: 'success'
            }
        ],
        [
            'error',
            {
                icon: 'mdi-alert-remove',
                color: 'error'
            }
        ]
    ]),
    reportsQueue: {}
};

export const getters = {
    reportsEnabled: state => state.reportsEnabled,
    isReportsOpened: state => state.isReportsOpened,
    reports: state => state.reports,
    notViewedReports: state => state.reports.data.filter(
        report => !report.attributes.is_viewed
    ),
    reportStates: state => state.reportStates,
    reportTypes: state => state.reportTypes,
    reportsLoadingIcons: state => state.reportsLoadingIcons,
    reportsQueue: state => state.reportsQueue
};

export const mutations = {
    OPEN_REPORTS(state) {
        state.isReportsOpened = true;
    },
    CLOSE_REPORTS(state) {
        state.isReportsOpened = false;
    },
    SET_REPORTS_LIST(state, reports) {
        state.reports = reports;
    },
    CLEAR_REPORTS_QUEUE(state) {
        state.reportsQueue = {};
    },
    ADD_REPORT_IN_QUEUE(state, report) {
        state.reportsQueue[report.attributes.type] = report;
    },
    REMOVE_REPORT_FROM_QUEUE(state, type) {
        delete state.reportsQueue[type];
    }
};

export const actions = {
    openReports({ commit }) {
        commit('OPEN_REPORTS');
    },
    closeReports({ commit }) {
        commit('CLOSE_REPORTS');
    },
    clearReports({ commit }) {
        commit('SET_REPORTS_LIST', {
            data: [],
            meta: {}
        });
    },
    async getReports({ commit }, params) {
        const reports = await Reports.getReportsList(params);

        commit('SET_REPORTS_LIST', reports);
    },
    listenMessages({ getters, commit, dispatch, rootGetters }) {
        const channel = `listener#${rootGetters['auth/userId']}`;

        rootGetters['ws/server'].subscribe(channel, ({ data: { payload } }) => {
            const { attributes, id } = payload;

            if (
                attributes.status === getters.reportStates.QUEUED ||
                attributes.status === getters.reportStates.IN_PROGRESS
            ) {
                dispatch('addReportInQueue', payload);
            } else {
                dispatch('removeReportFromQueue', attributes.type);
            }

            const reports = [...getters.reports.data];
            const currentReportIndex = reports.findIndex(
                report => report.id === id
            );

            if (currentReportIndex > -1) {
                reports.splice(currentReportIndex, 1, payload);
            } else {
                reports.unshift(payload);
            }

            commit('SET_REPORTS_LIST', {
                data: reports,
                meta: getters.reports.meta
            });
        });
    },
    clearReportsQueue({ commit }) {
        commit('CLEAR_REPORTS_QUEUE');
    },
    addReportInQueue({ commit }, report) {
        commit('ADD_REPORT_IN_QUEUE', report);
    },
    removeReportFromQueue({ commit }, type) {
        commit('REMOVE_REPORT_FROM_QUEUE', type);
    },
    hasReportInQueue({ getters }, type) {
        return getters.reportsQueue[type] !== undefined;
    },
    setReportsViewed({ commit }, data) {
        return Reports.setReportsViewed(data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

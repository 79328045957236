import auth from '@router/middleware/auth';
import isAdmin from '@router/middleware/isAdmin';

const Main = () => import('@admin/views/Main.vue');
const Dashboard = () => import('@admin/views/Dashboard.vue');

const ActivityTable = () => import('@admin/components/activities/views/ActivityTableView.vue');
const ActivityDetailsView = () => import('@admin/components/activities/views/ActivityDetailsView.vue');
const ActivityDetailsInfo = () => import('@admin/components/activities/components/ActivityDetailsInfo');
const ActivityDetailsSettings = () => import('@admin/components/activities/components/ActivityDetailsSettings');
const ActivityDetailsListeners = () => import('@admin/components/activities/components/ActivityDetailsListeners');

const UsersTable = () => import('@admin/components/users/views/UsersTableView.vue');
const UserDetailsView = () => import('@admin/components/users/views/UserDetailsView.vue');
const UserDetailsInfo = () => import('@admin/components/users/components/UserDetailsInfo');

const AssignmentsTable = () => import('@admin/components/assignments/views/AssignmentsTableView.vue');
const AssignmentsDetailsView = () => import('@admin/components/assignments/views/AssignmentsDetailsView.vue');
const AssignmentsDetailsInfo = () => import('@admin/components/assignments/components/AssignmentsDetailsInfo');
const AssignmentsDetailsListeners = () => import('@admin/components/assignments/components/AssignmentsDetailsListeners');

const ProgramsTable = () => import('@admin/components/programs/views/ProgramsTableView.vue');
const ProgramDetailsView = () => import('@admin/components/programs/views/ProgramDetailsView.vue');
const ProgramDetailsInfo = () => import('@admin/components/programs/components/ProgramDetailsInfo');
const ProgramDetailsSettings = () => import('@admin/components/programs/components/ProgramDetailsSettings');
const ProgramDetailsListeners = () => import('@admin/components/programs/components/ProgramDetailsListeners');

export default [
    {
        path: '/admin',
        component: Main,
        children: [
            {
                path: '/',
                name: 'dashboard',
                redirect: 'activities'
            },
            {
                path: 'activities',
                name: 'activities-table',
                component: ActivityTable,
                meta: {
                    middleware: [auth, isAdmin],
                    breadcrumbs: {
                        name: 'Список всех материалов'
                    }
                }
            },
            {
                path: 'activities/create',
                component: ActivityDetailsView,
                children: [
                    {
                        path: '',
                        name: 'activity-create',
                        component: ActivityDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Создать материал'
                            }
                        }
                    }
                ]
            },
            {
                path: 'activities/:activityId',
                name: 'activity-info',
                component: ActivityDetailsView,
                meta: {
                    breadcrumbs: {
                        name: '{{name}}',
                        disabled: true
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'activity-details',
                        component: ActivityDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Общая информация'
                            }
                        }
                    },
                    {
                        path: 'links',
                        name: 'activity-links',
                        component: ActivityDetailsSettings,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Доступ по ссылке'
                            }
                        }
                    },
                    {
                        path: 'listeners',
                        name: 'activity-listeners',
                        component: ActivityDetailsListeners,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Слушатели'
                            }
                        }
                    }
                ]
            },
            {
                path: 'users',
                name: 'users-table',
                component: UsersTable,
                meta: {
                    middleware: [auth, isAdmin],
                    breadcrumbs: {
                        name: 'Список пользователей'
                    }
                }
            },
            {
                path: 'users/create',
                component: UserDetailsView,
                children: [
                    {
                        path: '',
                        name: 'user-create',
                        component: UserDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Создать пользователя'
                            }
                        }
                    }
                ]
            },
            {
                path: 'users/:userId',
                name: 'user-info',
                component: UserDetailsView,
                meta: {
                    breadcrumbs: {
                        name: '{{name}}',
                        disabled: true
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'user-details',
                        component: UserDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Общая информация'
                            }
                        }
                    }
                ]
            },
            {
                path: 'assignments',
                name: 'assignments-table',
                component: AssignmentsTable,
                meta: {
                    middleware: [auth, isAdmin],
                    breadcrumbs: {
                        name: 'Список назначенного обучения'
                    }
                }
            },
            {
                path: 'assignments/create',
                component: AssignmentsDetailsView,
                children: [
                    {
                        path: '',
                        name: 'assignments-create',
                        component: AssignmentsDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Назначить обучение'
                            }
                        }
                    }
                ]
            },
            {
                path: 'assignments/:assignmentId',
                name: 'assignments-info',
                component: AssignmentsDetailsView,
                meta: {
                    breadcrumbs: {
                        name: '{{name}}',
                        disabled: true
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'assignments-details',
                        component: AssignmentsDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Общая информация'
                            }
                        }
                    },
                    {
                        path: 'listeners',
                        name: 'assignments-listeners',
                        component: AssignmentsDetailsListeners,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Слушатели'
                            }
                        }
                    }
                ]
            },
            {
                path: 'programs',
                name: 'programs-table',
                component: ProgramsTable,
                meta: {
                    middleware: [auth, isAdmin],
                    breadcrumbs: {
                        name: 'Список программ'
                    }
                }
            },
            {
                path: 'programs/create',
                component: ProgramDetailsView,
                children: [
                    {
                        path: '',
                        name: 'program-create',
                        component: ProgramDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Создать программу'
                            }
                        }
                    }
                ]
            },
            {
                path: 'programs/:programId',
                name: 'program-info',
                component: ProgramDetailsView,
                meta: {
                    breadcrumbs: {
                        name: '{{name}}',
                        disabled: true
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'program-details',
                        component: ProgramDetailsInfo,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Содержание программы'
                            }
                        }
                    },
                    {
                        path: 'settings',
                        name: 'program-settings',
                        component: ProgramDetailsSettings,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Настройка визуализации'
                            }
                        }
                    },
                    {
                        path: 'listeners',
                        name: 'program-listeners',
                        component: ProgramDetailsListeners,
                        meta: {
                            middleware: [auth, isAdmin],
                            breadcrumbs: {
                                name: 'Слушатели'
                            }
                        }
                    }
                ]
            },
            {
                path: 'tests',
                name: 'dashboards',
                component: Dashboard,
                meta: { middleware: [auth, isAdmin] }
            }
        ]
    }
];

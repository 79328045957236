/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo_mgpu': {
    width: 130,
    height: 95,
    viewBox: '0 0 130 95',
    data: `<defs>
    <style type="text/css">
     <![CDATA[
      .fil0 {fill:#B2B3B3;fill-rule:nonzero}
      .fil2 {fill:#50504F;fill-rule:nonzero}
      .fil1 {fill:#E53124;fill-rule:nonzero}
     ]]>
    </style>
   </defs>
   <g id="__x0023_Layer_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_1833403535360">
     <g>
      <g>
       <path class="fil0" d="M18.78 80.22l1.62 0 1.65 4.14c0.19,0.5 0.41,1.23 0.41,1.23l0.02 0c0,0 0.23,-0.73 0.42,-1.23l1.65 -4.14 1.61 0 0.69 8.37 -1.52 0 -0.34 -4.68c-0.05,-0.55 -0.02,-1.3 -0.02,-1.3l-0.02 0c0,0 -0.27,0.82 -0.46,1.3l-1.36 3.25 -1.32 0 -1.35 -3.25c-0.19,-0.48 -0.46,-1.31 -0.46,-1.31l-0.03 0c0,0 0.04,0.76 -0.01,1.31l-0.34 4.68 -1.52 0 0.68 -8.37z"/>
       <polygon class="fil0" points="30.32,80.22 35.14,80.22 35.14,81.53 31.84,81.53 31.84,88.59 30.32,88.59 "/>
       <polygon class="fil0" points="38.06,80.22 44.84,80.22 44.84,88.59 43.32,88.59 43.32,81.53 39.58,81.53 39.58,88.59 38.06,88.59 "/>
       <path class="fil0" d="M49.08 87.01c0.21,0.17 0.56,0.42 0.95,0.42 0.42,0 0.66,-0.27 0.83,-0.73l-3.28 -6.48 1.68 0 1.78 3.74c0.27,0.55 0.47,1.11 0.47,1.11l0.03 0c0,0 0.15,-0.56 0.34,-1.1l1.34 -3.75 1.59 0 -2.58 6.62c-0.35,0.89 -0.93,1.9 -2.15,1.9 -0.63,0 -1.16,-0.23 -1.67,-0.58l0.67 -1.15z"/>
      </g>
      <path class="fil1" d="M34.16 29.82c2.46,0 4.32,1.87 4.32,4.27 0,2.47 -1.86,4.38 -4.32,4.38 -2.47,0 -4.33,-1.91 -4.33,-4.38 0,-2.4 1.86,-4.27 4.33,-4.27zm0 7.26l0 0c1.53,0 2.75,-1.29 2.75,-2.99 0,-1.63 -1.22,-2.88 -2.75,-2.88 -1.54,0 -2.76,1.25 -2.76,2.88 0,1.7 1.22,2.99 2.76,2.99z"/>
      <g>
       <g>
        <polygon class="fil2" points="51.99,38.34 53.52,38.34 53.52,29.96 51.99,29.96 "/>
        <polygon class="fil2" points="58.29,29.96 56.61,29.96 53.85,33.97 56.84,38.34 58.66,38.34 55.5,33.91 "/>
       </g>
       <g>
        <polygon class="fil2" points="91.96,38.34 93.48,38.34 93.48,29.96 91.96,29.96 "/>
        <polygon class="fil2" points="98.26,29.96 96.58,29.96 93.81,33.97 96.8,38.34 98.63,38.34 95.47,33.91 "/>
       </g>
       <g>
        <polygon class="fil2" points="78.05,55.09 79.57,55.09 79.57,46.71 78.05,46.71 "/>
        <polygon class="fil2" points="84.35,46.71 82.67,46.71 79.9,50.72 82.89,55.09 84.72,55.09 81.56,50.66 "/>
       </g>
       <path class="fil2" d="M18.78 29.96l1.62 0 1.65 4.14c0.19,0.5 0.41,1.22 0.41,1.22l0.02 0c0,0 0.23,-0.72 0.42,-1.22l1.65 -4.14 1.61 0 0.69 8.38 -1.52 0 -0.34 -4.69c-0.05,-0.55 -0.02,-1.3 -0.02,-1.3l-0.02 0c0,0 -0.27,0.82 -0.46,1.3l-1.36 3.24 -1.32 0 -1.35 -3.24c-0.19,-0.48 -0.46,-1.31 -0.46,-1.31l-0.03 0c0,0 0.04,0.76 -0.01,1.31l-0.34 4.69 -1.52 0 0.68 -8.38z"/>
       <path class="fil2" d="M45.6 29.82c1.1,0 2.28,0.35 3.09,1.14l-0.73 1.13c-0.59,-0.5 -1.47,-0.88 -2.29,-0.88 -1.83,0 -2.8,1.33 -2.8,2.88 0,1.57 1,2.99 2.82,2.99 0.89,0 1.79,-0.43 2.43,-1.06l0.8 1.1c-0.72,0.73 -1.8,1.35 -3.3,1.35 -2.62,0 -4.32,-1.89 -4.32,-4.36 0,-2.43 1.78,-4.29 4.3,-4.29z"/>
       <path class="fil2" d="M64.89 29.82c2.47,0 4.33,1.87 4.33,4.27 0,2.47 -1.86,4.38 -4.33,4.38 -2.46,0 -4.33,-1.91 -4.33,-4.38 0,-2.4 1.87,-4.27 4.33,-4.27zm0 7.26l0 0c1.53,0 2.76,-1.29 2.76,-2.99 0,-1.63 -1.23,-2.88 -2.76,-2.88 -1.53,0 -2.76,1.25 -2.76,2.88 0,1.7 1.23,2.99 2.76,2.99z"/>
       <path class="fil2" d="M72.52 29.96l2.98 0c1.53,0 2.6,0.78 2.6,2.16 0,0.78 -0.38,1.45 -1.02,1.79l0 0.03c0.92,0.26 1.36,1.11 1.36,1.96 0,1.68 -1.34,2.44 -2.89,2.44l-3.03 0 0 -8.38zm2.99 3.42l0 0c0.66,0 1.04,-0.47 1.04,-1.07 0,-0.61 -0.36,-1.04 -1.06,-1.04l-1.45 0 0 2.11 1.47 0zm0.16 3.65l0 0c0.77,0 1.21,-0.51 1.21,-1.22 0,-0.7 -0.45,-1.2 -1.21,-1.2l-1.63 0 0 2.42 1.63 0z"/>
       <path class="fil2" d="M85.57 29.82c1.1,0 2.28,0.35 3.09,1.14l-0.73 1.13c-0.59,-0.5 -1.47,-0.88 -2.29,-0.88 -1.84,0 -2.81,1.33 -2.81,2.88 0,1.57 1.01,2.99 2.82,2.99 0.9,0 1.8,-0.43 2.43,-1.06l0.8 1.1c-0.71,0.73 -1.79,1.35 -3.29,1.35 -2.62,0 -4.32,-1.89 -4.32,-4.36 0,-2.43 1.78,-4.29 4.3,-4.29z"/>
       <path class="fil2" d="M101.25 29.96l1.52 0 0 4.83c0,0.5 -0.08,1.3 -0.08,1.3l0.02 0c0,0 0.4,-0.81 0.71,-1.3l3.16 -4.83 1.51 0 0 8.38 -1.51 0 0 -4.83c0,-0.5 0.08,-1.29 0.08,-1.29l-0.02 0c0,0 -0.39,0.79 -0.7,1.29l-3.18 4.83 -1.51 0 0 -8.38z"/>
       <path class="fil2" d="M111.9 29.96l1.52 0 0 4.83c0,0.5 -0.08,1.3 -0.08,1.3l0.02 0c0,0 0.4,-0.81 0.7,-1.3l3.17 -4.83 1.51 0 0 8.38 -1.51 0 0 -4.83c0,-0.5 0.08,-1.29 0.08,-1.29l-0.02 0c0,0 -0.39,0.79 -0.7,1.29l-3.19 4.83 -1.5 0 0 -8.38zm1.55 -2.01l0 0 0 -0.15 1.15 0 0 0.15c0,0.51 0.4,0.75 0.79,0.75 0.39,0 0.8,-0.24 0.8,-0.76l0 -0.14 1.13 0 0 0.15c0,1.04 -0.91,1.63 -1.93,1.63 -1.03,0 -1.94,-0.59 -1.94,-1.63z"/>
       <polygon class="fil2" points="18.42,46.71 23.23,46.71 23.23,48.02 19.94,48.02 19.94,55.09 18.42,55.09 "/>
       <path class="fil2" d="M29.69 46.57c2.46,0 4.33,1.88 4.33,4.27 0,2.47 -1.87,4.39 -4.33,4.39 -2.47,0 -4.33,-1.92 -4.33,-4.39 0,-2.39 1.86,-4.27 4.33,-4.27zm0 7.27l0 0c1.53,0 2.76,-1.3 2.76,-3 0,-1.62 -1.23,-2.88 -2.76,-2.88 -1.53,0 -2.76,1.26 -2.76,2.88 0,1.7 1.23,3 2.76,3z"/>
       <path class="fil2" d="M37.31 46.71l3.24 0c1.56,0 2.66,1.1 2.66,2.73 0,1.63 -1.1,2.76 -2.66,2.76l-1.71 0 0 2.89 -1.53 0 0 -8.38zm2.95 4.17l0 0c0.89,0 1.41,-0.57 1.41,-1.44 0,-0.86 -0.52,-1.42 -1.38,-1.42l-1.45 0 0 2.86 1.42 0z"/>
       <path class="fil2" d="M50.13 46.57c2.46,0 4.33,1.88 4.33,4.27 0,2.47 -1.87,4.39 -4.33,4.39 -2.47,0 -4.33,-1.92 -4.33,-4.39 0,-2.39 1.86,-4.27 4.33,-4.27zm0 7.27l0 0c1.53,0 2.76,-1.3 2.76,-3 0,-1.62 -1.23,-2.88 -2.76,-2.88 -1.53,0 -2.76,1.26 -2.76,2.88 0,1.7 1.23,3 2.76,3z"/>
       <path class="fil2" d="M57.4 53.78c1.04,-1.57 1.22,-3.54 1.22,-5.24l0 -1.83 5.2 0 0 7.07 0.93 0 0 2.96 -1.35 0 0 -1.65 -5.44 0 0 1.65 -1.4 0 0 -2.96 0.84 0zm4.9 0l0 0 0 -5.76 -2.24 0 0 0.63c0,1.64 -0.21,3.72 -1.09,5.13l3.33 0z"/>
       <path class="fil2" d="M71.66 46.57c1.1,0 2.28,0.36 3.09,1.15l-0.73 1.13c-0.59,-0.51 -1.48,-0.89 -2.29,-0.89 -1.84,0 -2.81,1.34 -2.81,2.88 0,1.57 1.01,3 2.82,3 0.9,0 1.79,-0.44 2.43,-1.06l0.8 1.09c-0.72,0.73 -1.79,1.36 -3.29,1.36 -2.63,0 -4.33,-1.9 -4.33,-4.36 0,-2.43 1.79,-4.3 4.31,-4.3z"/>
       <path class="fil2" d="M90.95 46.57c2.46,0 4.33,1.88 4.33,4.27 0,2.47 -1.87,4.39 -4.33,4.39 -2.47,0 -4.33,-1.92 -4.33,-4.39 0,-2.39 1.86,-4.27 4.33,-4.27zm0 7.27l0 0c1.53,0 2.76,-1.3 2.76,-3 0,-1.62 -1.23,-2.88 -2.76,-2.88 -1.53,0 -2.76,1.26 -2.76,2.88 0,1.7 1.23,3 2.76,3z"/>
       <path class="fil2" d="M98.57 46.71l1.52 0 0 4.84c0,0.49 -0.07,1.3 -0.07,1.3l0.02 0c0,0 0.4,-0.82 0.7,-1.31l3.17 -4.83 1.51 0 0 8.38 -1.51 0 0 -4.83c0,-0.49 0.08,-1.29 0.08,-1.29l-0.03 0c0,0 -0.39,0.8 -0.7,1.29l-3.17 4.83 -1.52 0 0 -8.38zm1.56 -2l0 0 0 -0.16 1.14 0 0 0.16c0,0.51 0.41,0.74 0.8,0.74 0.38,0 0.8,-0.23 0.8,-0.75l0 -0.15 1.13 0 0 0.16c0,1.04 -0.91,1.63 -1.93,1.63 -1.03,0 -1.94,-0.59 -1.94,-1.63z"/>
       <path class="fil2" d="M18.86 70.26c0.22,0.17 0.56,0.41 0.95,0.41 0.42,0 0.66,-0.26 0.83,-0.73l-3.28 -6.47 1.68 0 1.78 3.74c0.27,0.55 0.47,1.11 0.47,1.11l0.03 0c0,0 0.15,-0.56 0.34,-1.1l1.34 -3.75 1.6 0 -2.59 6.62c-0.35,0.89 -0.93,1.89 -2.14,1.89 -0.64,0 -1.17,-0.22 -1.68,-0.57l0.67 -1.15z"/>
       <polygon class="fil2" points="27.4,63.47 28.92,63.47 28.92,67.02 32.76,67.02 32.76,63.47 34.26,63.47 34.26,71.84 32.76,71.84 32.76,68.33 28.92,68.33 28.92,71.84 27.4,71.84 "/>
       <path class="fil2" d="M38.06 63.47l1.52 0 0 4.83c0,0.5 -0.09,1.3 -0.09,1.3l0.03 0c0,0 0.4,-0.81 0.71,-1.31l3.16 -4.82 1.51 0 0 8.37 -1.51 0 0 -4.82c0,-0.49 0.08,-1.3 0.08,-1.3l-0.02 0c0,0 -0.39,0.81 -0.7,1.3l-3.18 4.82 -1.51 0 0 -8.37z"/>
       <path class="fil2" d="M48.7 63.47l2.99 0c1.52,0 2.59,0.78 2.59,2.16 0,0.78 -0.38,1.45 -1.01,1.79l0 0.03c0.92,0.27 1.35,1.12 1.35,1.96 0,1.68 -1.34,2.43 -2.89,2.43l-3.03 0 0 -8.37zm3 3.42l0 0c0.66,0 1.04,-0.47 1.04,-1.08 0,-0.6 -0.37,-1.03 -1.06,-1.03l-1.45 0 0 2.11 1.47 0zm0.15 3.64l0 0c0.78,0 1.22,-0.5 1.22,-1.21 0,-0.69 -0.45,-1.2 -1.22,-1.2l-1.62 0 0 2.41 1.62 0z"/>
       <polygon class="fil2" points="57.93,63.47 62.95,63.47 62.95,64.78 59.46,64.78 59.46,66.97 62.27,66.97 62.27,68.28 59.46,68.28 59.46,70.53 63.14,70.53 63.14,71.84 57.93,71.84 "/>
       <path class="fil2" d="M66.41 63.47l3.23 0c1.57,0 2.67,1.09 2.67,2.72 0,1.63 -1.1,2.76 -2.67,2.76l-1.71 0 0 2.89 -1.52 0 0 -8.37zm2.95 4.16l0 0c0.89,0 1.4,-0.56 1.4,-1.44 0,-0.86 -0.51,-1.41 -1.38,-1.41l-1.45 0 0 2.85 1.43 0z"/>
       <path class="fil2" d="M79.22 63.33c1.09,0 2.27,0.35 3.09,1.14l-0.73 1.13c-0.59,-0.5 -1.48,-0.88 -2.29,-0.88 -1.84,0 -2.81,1.33 -2.81,2.88 0,1.57 1,2.99 2.82,2.99 0.9,0 1.79,-0.43 2.43,-1.05l0.8 1.09c-0.72,0.73 -1.79,1.35 -3.29,1.35 -2.63,0 -4.33,-1.89 -4.33,-4.36 0,-2.43 1.78,-4.29 4.31,-4.29z"/>
       <path class="fil2" d="M85.6 63.47l1.53 0 0 4.83c0,0.5 -0.09,1.3 -0.09,1.3l0.03 0c0,0 0.4,-0.81 0.71,-1.31l3.16 -4.82 1.51 0 0 8.37 -1.51 0 0 -4.82c0,-0.49 0.08,-1.3 0.08,-1.3l-0.02 0c0,0 -0.39,0.81 -0.7,1.3l-3.19 4.82 -1.51 0 0 -8.37z"/>
       <polygon class="fil2" points="98.15,64.78 95.4,64.78 95.4,63.47 102.41,63.47 102.41,64.78 99.66,64.78 99.66,71.84 98.15,71.84 "/>
       <polygon class="fil2" points="105.36,63.47 110.38,63.47 110.38,64.78 106.89,64.78 106.89,66.97 109.7,66.97 109.7,68.28 106.89,68.28 106.89,70.53 110.57,70.53 110.57,71.84 105.36,71.84 "/>
       <polygon class="fil2" points="115.74,64.78 112.99,64.78 112.99,63.47 120,63.47 120,64.78 117.25,64.78 117.25,71.84 115.74,71.84 "/>
      </g>
     </g>
     <path class="fil1" d="M11.29 54.8c-4.94,-5.47 -7.95,-12.71 -7.95,-20.65 0,-16.99 13.82,-30.81 30.81,-30.81 12.97,0 24.08,8.05 28.62,19.41l3.1 -1.23c-5.03,-12.59 -17.35,-21.52 -31.72,-21.52 -18.83,0 -34.15,15.32 -34.15,34.15 0,8.8 3.34,16.82 8.82,22.88l2.47 -2.23z"/>
    </g>
   </g>`
  }
})

import Ws from '@client/api/ws';
import Centrifuge from '@global/centrifuge';

export const state = {
    config: {},
    server: null
};

export const getters = {
    expired: state => state.config.expired,
    token: state => state.config.token,
    endpoint: state => state.config.wsEndpoint,
    server: state => state.server,
    isServerConnected: state => state.server !== null
};

export const mutations = {
    SET_CONFIG: (state, config) => {
        state.config = config;
    },
    SET_SERVER: (state, server) => {
        state.server = server;
    },
    REMOVE_SERVER: state => {
        state.server = null;
    }
};

export const actions = {
    init: async ({ commit, dispatch }) => {
        const config = await Ws.getConfig();

        commit('SET_CONFIG', config);
        dispatch('initCentrifuge');
    },

    initCentrifuge({ getters, commit, dispatch }) {
        const options = {
            async onRefresh(ctx, cb) {
                try {
                    const config = await Ws.getConfig();
                    commit('SET_CONFIG', config);

                    const data = {
                        status: 200,
                        data: {
                            token: getters.token
                        }
                    };
                    cb(data);
                } catch (e) {
                    console.error(e);
                }
            }
        };
        const centrifuge = new Centrifuge(getters.endpoint, options);

        centrifuge.setToken(getters.token);
        centrifuge.connect();

        commit('SET_SERVER', centrifuge);
    },
    disconnect: ({ state, commit }) => {
        state.server.disconnect();
        commit('REMOVE_SERVER');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

<template>
    <div class="km-skeleton-table skeleton">
        <div
            v-for="index in 6"
            :key="index"
            class="skeleton__block skeleton__bg"
        >
            <div class="skeleton__block-columns" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'kmSkeletonTable'
};
</script>

<style lang="scss" scoped>
    .skeleton {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;

        &__block {
            display: flex;
            height: 20px;
            width: 100%;
            margin-top: 20px;

            &-columns {
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to right,
                    transparent,
                    transparent 18%,
                    white 18%,
                    white 22%,
                    transparent 22%,
                    transparent 48%,
                    white 48%,
                    white 52%,
                    transparent 52%,
                    transparent 78%,
                    white 78%,
                    white 82%,
                    transparent 82%,
                    transparent 100%
                );
            }
        }

        &__bg {
            background-size: 300%;
            background-image: linear-gradient(to right, #e6ecf0 30%, #f1f4f6 50%, #e6ecf0 70%);
            animation: loading 1.5s infinite;
        }
    }
</style>

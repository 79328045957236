import Programs from '@admin/api/programs';

export const state = {
    programs: {
        data: [],
        meta: {}
    },
    programById: {},
    programUsers: {
        data: [],
        meta: {}
    },
    programStyles: [],
    loadedSize: {}
};

export const getters = {
    programs: state => state.programs,
    programById: state => state.programById,
    programUsers: state => state.programUsers,
    programStyles: state => state.programStyles,
    loadedSize: state => state.loadedSize
};

export const mutations = {
    SET_PROGRAMS_LIST: (state, programs) => {
        state.programs = programs;
    },
    SET_PROGRAM_BY_ID: (state, program) => {
        state.programById = program;
    },
    SET_PROGRAM_USERS_LIST: (state, users) => {
        state.programUsers = users;
    },
    SET_PROGRAMS_STYLES_LIST: (state, styles) => {
        state.programStyles = styles;
    },
    SET_SIZE_LOADED: (state, { loaded, total }) => {
        state.loadedSize = { loaded, total };
    }
};

export const actions = {
    clearProgram({ commit }) {
        commit('SET_PROGRAM_BY_ID', {});
    },
    clearPrograms({ commit }) {
        commit('SET_PROGRAMS_LIST', {
            data: [],
            meta: {}
        });
    },
    getPrograms({ commit }, params) {
        return Programs.getProgramsList(params).then(res => {
            commit('SET_PROGRAMS_LIST', res);
        });
    },
    getProgramDetails({ commit }, params) {
        return Programs.getProgram(params).then(res => {
            commit('SET_PROGRAM_BY_ID', res.data);
        });
    },
    getProgramUsers({ commit }, params) {
        return Programs.getProgramUsersList(params).then(res => {
            commit('SET_PROGRAM_USERS_LIST', res);
        });
    },
    updateProgram({ commit }, { params, data }) {
        return Programs.updateProgram({ params, data });
    },
    createProgram({ commit }, data) {
        return Programs.createProgram(data);
    },
    deleteProgram({ commit }, params) {
        return Programs.deleteProgram(params);
    },
    toggleProgramActivity({ commit }, data) {
        return Programs.toggleProgramActivity(data);
    },
    getProgramStyles({ commit }) {
        return Programs.getProgramStylesList().then(res => {
            commit('SET_PROGRAMS_STYLES_LIST', res.data);
        });
    },
    uploadProgramStyle({ dispatch }, data) {
        const config = {
            onUploadProgress: progressEvent => dispatch('setLoadedSize', progressEvent)
        };
        return Programs.uploadProgramStyle(data, config);
    },
    setLoadedSize({ commit }, { loaded, total }) {
        commit('SET_SIZE_LOADED', { loaded, total });
    },
    deleteProgramStyle({ commit }, data) {
        return Programs.deleteProgramStyle(data);
    },
    downloadUsersResults({ commit }, params) {
        return Programs.downloadUsersResults(params);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

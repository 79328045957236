/* eslint-disable */
const icon = require('vue-svgicon')
icon.register({
  'error_403': {
    viewBox: '0 0 364 471',
    data: `
    <g id="illustration" transform="translate(36 0)">
        <path d="M238.189 33.4798C232.891 25.8652 253.28 46.6529 253.743 47.162C254.207 47.671 272.814 67.6458 269.38 67.6458L279.567 88.6729L285.655 106.341L289.761 125.877L291.086 147.067L288.966 169.53L281.085 195.493L271.547 213.883L257.705 238.065L241.545 264.537L230.418 288.527L224.06 315.381L221.278 343.38C221.278 343.38 217.37 354.579 216.112 356.234C214.853 357.888 208.032 364.507 208.032 364.507L204.124 367.052L186.374 370.679L153.06 371.061L118.288 371.379C118.288 371.379 99.0812 369.852 97.4254 369.47C95.7697 369.088 83.848 366.288 80.0066 362.597C76.1652 358.907 70.0719 349.107 69.2109 343.507C68.3499 337.907 68.2175 322.826 68.2175 322.826C68.2175 322.826 65.4357 306.726 64.0449 301.381C62.5878 296.099 57.0906 279.872 55.5673 276.627C54.044 273.382 46.8248 258.682 45.5001 257.282C44.1755 255.882 31.989 233.674 29.4059 231.701C26.8229 229.728 13.9078 203.575 12.517 200.329C11.1261 197.084 4.30431 179.266 4.63547 178.375C4.96662 177.421 0.264204 156.103 0.131742 154.831C-0.000720872 153.558 -5.81253 62.5404 77.3132 16.7626C160.439 -29.0151 238.189 33.4798 238.189 33.4798Z" transform="translate(0.9144175 1.620967)" id="Shape" fill="#FFFFFF" stroke="none" />
        <path d="M252.018 23.7134C255.334 28.7548 277.284 68.5769 275.958 105.654C274.499 146.241 257.854 165.641 253.411 175.023C248.968 184.403 220.452 223.97 217.07 251.155C213.688 278.341 214.948 297.55 210.969 305.208C206.99 312.866 198.502 319.12 187.692 319.694C185.438 319.949 158.182 323.395 95.3149 319.886C83.5771 318.29 74.3593 310.185 73.4972 296.21C72.6351 282.17 73.9614 263.025 55.592 224.735C37.2227 186.446 18.7207 161.749 11.5586 138.711C6.25339 121.544 5.72287 112.61 5.8555 108.207C5.92181 106.548 4.72814 105.08 3.00394 104.697C1.14711 104.25 -0.179199 105.399 0.0197472 107.25C1.14711 116.95 4.06498 138.902 8.4418 148.539C14.2775 161.43 27.5406 187.913 33.8406 195.89C40.1405 203.867 51.9447 226.522 52.9394 230.224C53.9341 233.925 63.7488 255.942 65.2077 268.194C66.4014 277.767 67.4625 281.404 67.8604 282.553C67.993 282.872 68.0593 283.255 68.0593 283.574C68.1256 286.956 68.7225 304.059 72.6351 310.185C77.0119 317.014 81.5213 324.799 91.1371 326.905C100.753 329.011 134.109 328.884 134.109 328.884L166.936 329.522C168.593 329.522 171.312 329.522 172.97 329.458C188.952 328.756 196.38 327.863 199.43 327.416C203.807 326.778 207.852 324.991 213.622 321.353C215.014 320.524 220.85 313.568 221.38 303.421C221.712 297.741 223.569 279.298 224.033 277.192C224.497 275.086 226.553 259.324 229.802 250.836C232.787 243.115 241.739 221.545 253.145 204.442C263.159 189.509 265.812 183.191 266.276 181.851C268.464 178.149 283.982 153.006 285.308 143.178C285.839 140.945 289.486 127.16 290.083 122.438C290.68 117.396 291.807 94.0396 290.083 83.8928C288.359 73.746 284.601 59.7751 281.82 50.2976C279.038 40.8202 266.674 21.2666 265.414 19.0969C265.348 18.9693 254.472 5.44019 254.472 5.44019C244.127 -7.70597 248.835 6.73821 249.299 9.60995L251.488 22.3732C251.554 22.8199 251.753 23.2666 252.018 23.7134Z" transform="translate(1 44.47818)" id="Shape" fill="#69BFF8" fill-opacity="0.19" stroke="none" />
        <g id="Group">
            <path d="M84.3261 368.256C69.7424 356.017 69.1458 344.48 68.6817 331.349C68.5492 326.249 67.9525 321.15 66.9582 316.114C61.5225 288.896 55.0924 276.339 45.4804 259.383C41.4367 252.244 37.1279 245.36 32.5539 238.54C1.79554 192.836 -0.12686 158.543 0.00571872 149.491C0.00571872 124.135 8.12999 71.1696 53.6327 33.1205C137.62 -37.1092 266.289 11.4526 287.172 112.138C294.988 149.823 287.172 181.171 285.581 187.163C285.184 188.629 283.99 191.816 283.526 193.219C279.284 205.266 249.056 255.75 246.802 259.256" transform="translate(0.6571772 0.7913872)" id="Shape" fill="none" stroke="#333435" stroke-width="1.5" stroke-linecap="round" />
        </g>
        <path d="M2.49057 0.973991L0.509434 0.973991C0.226415 0.973991 0 0.757549 0 0.486996L0 0.486996C0 0.216442 0.226415 0 0.509434 0L2.49057 0C2.77358 0 3 0.216442 3 0.486996L3 0.486996C3 0.757549 2.77358 0.973991 2.49057 0.973991L2.49057 0.973991Z" transform="translate(153 465)" id="Shape" fill="#FFFFFF" stroke="none" />
        <path d="M8.70248 0C8.70248 0 9.3719 26.2944 8.70248 28.8613C8.03306 31.4281 5.5562 35.7479 0 37" transform="translate(130 230)" id="Shape" fill="none" stroke="#FFFFFF" stroke-width="0.5" />
        <path d="M51.1378 3L0.862245 3C0.397959 3 0 2.52632 0 1.97368L0 1.02632C0 0.473684 0.397959 0 0.862245 0L51.1378 0C51.602 0 52 0.473684 52 1.02632L52 1.97368C52 2.52632 51.6684 3 51.1378 3L51.1378 3Z" transform="translate(119 371)" id="Shape" fill="#333435" stroke="none" />
        <path d="M5.09706 3L0.895429 3C0.413275 3 2.84217e-14 2.52632 2.84217e-14 1.97368L2.84217e-14 1.02632C2.84217e-14 0.473684 0.413275 0 0.895429 0L5.09706 0C5.57921 0 5.99249 0.473684 5.99249 1.02632L5.99249 1.97368C6.06137 2.52632 5.64809 3 5.09706 3L5.09706 3Z" transform="translate(174 371)" id="Shape" fill="#333435" stroke="none" />
        <path d="M10.0571 0.0124595L103.04 0.0124595C105.278 0.0124595 107.449 0.649592 109.16 1.92386C111.2 3.45298 113.24 6.12893 112.977 10.7163C112.648 16.3868 108.568 18.6804 105.541 19.6361C104.949 19.8273 104.949 20.6556 105.541 20.8467C108.7 21.8661 112.977 24.5421 112.977 31.3594C112.977 36.9024 109.292 39.5147 106.396 40.6615C105.344 41.1075 105.278 42.4455 106.199 43.0189C109.226 44.7392 113.306 48.1797 112.911 54.2325C112.714 57.0996 111.463 59.3295 109.884 60.9861C107.318 63.5983 103.698 65 99.9475 65L11.6364 65C7.95132 65 4.39782 63.4072 2.22624 60.4764C0.910128 58.6924 -0.0769548 56.2713 0.120462 53.0219C0.44949 46.8417 4.72685 44.2932 7.62229 43.2101C8.41196 42.9552 8.41196 41.8721 7.6881 41.5535C4.59524 40.1518 0.0546563 36.9662 0.0546563 30.7223C0.0546563 25.0518 4.00299 22.4395 7.16166 21.2927C8.08293 20.9741 8.08293 19.6999 7.16166 19.3813C3.80557 18.0433 -0.537594 15.1125 0.0546563 8.42261C0.44949 -0.688387 10.0571 0.0124595 10.0571 0.0124595L10.0571 0.0124595Z" transform="translate(94 382)" id="Shape" fill="#5FBEFE" stroke="#333435" stroke-width="1.5" />
        <path d="M1.23276 0L65.7732 0C66.8195 0 67.3426 1.14894 66.7541 1.97872C62.9614 6.51064 51.9758 17.4255 33.2741 18C15.2917 18 4.10995 6.6383 0.317298 1.97872C-0.401998 1.14894 0.186517 0 1.23276 0L1.23276 0Z" transform="translate(117 453)" id="Shape" fill="#5FBEFE" stroke="#333435" stroke-width="1.5" />
        <path d="M75.0441 12L5.95588 12C2.64706 12 0 9.48691 0 6.34555L0 5.65445C0 2.51309 2.64706 0 5.95588 0L75.0441 0C78.3529 0 81 2.51309 81 5.65445L81 6.34555C81 9.48691 78.3529 12 75.0441 12L75.0441 12Z" transform="translate(111 394)" id="Shape" fill="#FFFFFF" stroke="none" />
        <path d="M58.0984 12L5.90164 12C2.62295 12 0 9.48691 0 6.34555L0 5.65445C0 2.51309 2.62295 5.68434e-14 5.90164 5.68434e-14L58.0984 5.68434e-14C61.377 5.68434e-14 64 2.51309 64 5.65445L64 6.34555C64 9.42408 61.3115 12 58.0984 12L58.0984 12Z" transform="translate(111 419)" id="Shape" fill="#FFFFFF" stroke="none" />
        <path d="M55.8111 3L1.18888 3C0.528389 3 0 2.33333 0 1.5L0 1.5C0 0.666667 0.528389 0 1.18888 0L55.8111 0C56.4716 0 57 0.666667 57 1.5L57 1.5C57 2.33333 56.4716 3 55.8111 3L55.8111 3Z" transform="translate(115 431)" id="Shape" fill="#13517C" fill-opacity="0.31" stroke="none" />
        <path d="M72.7978 3L1.20217 3C0.534296 3 0 2.33333 0 1.5L0 1.5C0 0.666667 0.534296 0 1.20217 0L72.7978 0C73.4657 0 74 0.666667 74 1.5L74 1.5C74 2.33333 73.3989 3 72.7978 3L72.7978 3Z" transform="translate(116 407)" id="Shape" fill="#13517C" fill-opacity="0.31" stroke="none" />
        <path d="M0 0C0 0 3.3075 1.2 2.97675 6" transform="translate(198 386)" id="Shape" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" />
        <path d="M6 0C6 0 5.27711 2.73684 2.84217e-14 3" transform="translate(194 438)" id="Shape" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" />
        <path d="M15 0C15 0 9.59746 4.51546 0 6" transform="translate(157 458)" id="Shape" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" />
        <path d="M0 27C0 27 24.096 27.0628 26 0C26 0 16.8737 26.1837 0 27L0 27Z" transform="translate(188 336)" id="Shape" fill="none" stroke="none" />
        <path d="M26 5.68434e-14C26 5.68434e-14 25.2667 22.266 0 23" transform="translate(196 347)" id="Shape" fill="none" stroke="#333435" stroke-width="1.5" stroke-linecap="round" />
    </g>`
  }
});

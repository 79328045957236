<template>
    <ErrorPage :title="title" :error-number="number">
        <template v-slot:report>
            <div class="report-text">
                <p>
                    Упс!<br>
                    Страница не существует или она была перенесена.<br>
                    Проверьте, что все данные введены корректно.
                </p>
            </div>
            <v-btn
                v-if="hasAssignments"
                :to="{ name: 'assignments' }"
                class="report-button"
                depressed
                exact
            >Главная страница</v-btn>
        </template>
    </ErrorPage>
</template>

<script>
import ErrorPage from '@components/error/ErrorPage';

export default {
    components: {
        ErrorPage
    },
    props: {
        hasAssignments: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: 'Ошибка:',
            number: '404'
        };
    }
};
</script>

<style lang="scss" scoped>
.report-text {
    margin-bottom: 16px;
}

.report-button {
    height: auto !important;
    min-height: 40px;
    margin: 0 !important;
    white-space: normal;

    ::v-deep span {
        flex-shrink: 1;
        padding: 8px 0;
    }
}
</style>
